import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { $monitoringStore } from "../../store/MonitoringStore";
import { type MRT_ColumnDef } from "material-react-table";
import Table from "../../ui/Table/Table";
import { Level } from "../../types/ui/table";

const StyledUserInfo = styled(Box, { shouldForwardProp: (prop) => prop !== "drawerWidth" })<{
    open?: boolean;
    drawerWidth: number;
}>(({ theme, open, drawerWidth }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }),
    ...(!open && {
        width: 0,
        marginRight: open ? "15px" : 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    })
}));

const UserInfo = observer(() => {
    const { usersDrawerIsOpen, drawerWidth, usersData, Markers, loadingUsersData } =
        $monitoringStore;

    const scrollToMarker = useCallback(
        (event: any) => {
            const userId = event.target.dataset.id;
            Markers.map((item) => {
                if (item.id === userId) {
                    $monitoringStore.setCurrentMarker(item.marker);
                }
                return item;
            });
        },
        [Markers]
    );

    function transformJsonToArray(json: any) {
        function processItems(items: any) {
            let result: Level[] = [];

            for (let key in items) {
                let item = items[key];
                let newItem: any = {
                    ...item,
                    items: item.items ? processItems(item.items) : []
                };
                result.push(newItem);
            }
            return result;
        }
        return processItems(json);
    }

    const data = useMemo(() => transformJsonToArray(usersData), [usersData]);
    const columns = useMemo<MRT_ColumnDef<Level>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Сотрудники",
                size: 200,
                muiTableBodyCellProps: {
                    size: "small",
                    padding: "none"
                },
                muiTableHeadCellProps: {
                    size: "small",
                    padding: "none",
                    align: "center"
                },
                Cell: ({ renderedCellValue, row }) => {
                    const points = Markers.filter((item) => {
                        return item.id === row.original.guid;
                    });

                    if (row.original.type === "user" && points.length > 0) {
                        return (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: 300 - 16 * row.depth + "px",
                                    whiteSpace: "nowrap",
                                    marginLeft: 8 * row.depth + "px",
                                    cursor: "pointer"
                                }}
                                data-id={row.original.guid}
                                onClick={scrollToMarker}
                                title={row.original.full_name}
                            >
                                {row.original.name}
                            </div>
                        );
                    } else {
                        return (
                            <Box
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: 300 - 16 * row.depth + "px",
                                    whiteSpace: "nowrap",
                                    marginLeft: 8 * row.depth + "px"
                                }}
                                title={row.original.full_name}
                            >
                                {row.original.name}
                            </Box>
                        );
                    }
                }
            },
            {
                accessorKey: "count",
                header: "План/Факт",
                size: 30,
                muiTableBodyCellProps: {
                    size: "small",
                    padding: "none"
                },
                muiTableHeadCellProps: {
                    size: "small",
                    padding: "none",
                    align: "center"
                },
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.type !== "user") {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                {row.original.count +
                                    "/" +
                                    row.original.active +
                                    "/" +
                                    row.original.inactive}
                            </Box>
                        );
                    }
                }
            }
        ],
        [Markers, scrollToMarker]
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 999,
                    height: "100%",
                    boxShadow: "0px 0px 5px 5px rgba(0, 0, 0, 0.2)"
                }}
            >
                <StyledUserInfo open={usersDrawerIsOpen} drawerWidth={drawerWidth}>
                    <Box
                        sx={{
                            width: 500,
                            height: "100%",
                            marginLeft: 0
                        }}
                    >
                        <Table
                            data={data}
                            columns={columns}
                            loading={loadingUsersData}
                            enableExpanding={true}
                        />
                    </Box>
                </StyledUserInfo>
            </Box>
        </>
    );
});

export default UserInfo;
