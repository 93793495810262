import {
    Autocomplete,
    Grid,
    TextField,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonSquare } from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    generatePath,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { StyledCard } from '../../App';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';
import CustomMap from '../../components/Zone/Map';
import { $houseListStore } from '../../store/HouseListData';
import { $loginUserStore } from '../../store/ResponseData';
import { $zoneTableDataStore } from '../../store/ZoneTableStore';
import { NewZoneDetails } from '../../types/newZoneDetails';
import MainContentWrap from '../../ui/Wrappers/MainContentWrap';
import { AppRoute } from '../../utils/const';
import { getUKName } from '../../utils/utils';
import { saveZone } from './ZoneListApi';

const StyledTextField = styled(TextField)({
    margin: "19px 0 9px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "12px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap"
});

const AddNewZone = observer(() => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [selectedAddress, setSelectedAddress] = useState({
        id: '',
        label: '',
    });

    const defaultBackUrl = generatePath(AppRoute.ZoneList);
    const [backurl, setBackurl] = useState(defaultBackUrl);
    const [showButtons, setShowButtons] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form, setForm] = useState<NewZoneDetails>({
        id: '',
        coordinates: undefined,
        description: '',
        house: '',
        uk: '',
        guid: '',
        name: '',
        loaded: false,
    });

    if (!$loginUserStore.isAdmin()) {
        navigate(AppRoute.ZoneList);
    }

    const { zoneCoordinates } = $zoneTableDataStore;

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    const {
        data,
    } = $houseListStore;

    const addresses = useMemo(() => {
        return data?.items.map((item) => {
            return {
                id: item.guid,
                label: item.address
            };
        });
    }, [data?.items]);

    useEffect(() => {
        setBackurl(searchParams.get('backurl') ?? defaultBackUrl);
        const curHouse = searchParams.get('house');
        if (curHouse !== null && form.house !== curHouse) {
            $houseListStore.getData(curHouse);

            handleFormChange(undefined, {
                id: curHouse,
                label: searchParams.get('name'),
            });
        }
    }, [data?.items]);

    const handleFormChange = useCallback(
        (evt: SyntheticEvent<Element, Event> | undefined, value: any) => {
            const currentItem = data?.items.find((item) => {
                return item.guid === value?.id
            });

            if (typeof currentItem !== 'undefined') {
                setForm({
                    ...form,
                    uk: currentItem?.uk!,
                    name: currentItem?.address as string,
                    house: currentItem?.guid!,
                    loaded: true,
                });

                $zoneTableDataStore.setNewCoordinates(
                    currentItem?.point!
                );

                if (backurl !== defaultBackUrl) {
                    $houseListStore.getData(currentItem?.address as string);
                }
            } else {
                setForm({
                    ...form,
                    loaded: false,
                });
            }

            setSelectedAddress(value);
        },
        [data?.items, form]
    );

    const handleClick = () => {
        $zoneTableDataStore.setShowZoneDetailes(false);
        navigate(generatePath(AppRoute.ZoneList));
    };

    useEffect(() => {
        setFormValue('coordinates', JSON.stringify(zoneCoordinates));
    }, [zoneCoordinates, setFormValue]);

    useEffect(() => {
        if (form.house && zoneCoordinates) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [form.house, zoneCoordinates]);

    // при изменении адреса сбрасываем сохраненные координаты зоны
    useEffect(() => {
        $zoneTableDataStore.setCoordinates(undefined);
    }, [form.house]);

    const handleSubmit = async () => {
        setSaving(true);
        $zoneTableDataStore.setLoadingZoneTable(true);
        await saveZone(form).then((zone) => {
            if (zone) {
                navigate(backurl);
                setSaving(false);
            }
        });
        $zoneTableDataStore.setLoadingZoneTable(false);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Создание новой зоны</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={3}>
                        <Grid item xs={8} lg={8} sm={7} md={8}>
                            {
                                saving
                                    || (!form.loaded && backurl !== defaultBackUrl)
                                    ? (
                                        <div
                                            style={{
                                                height: 700,
                                                border: '1px solid #5ac8fa',
                                            }}
                                        ><CustomCircularProgress/></div>
                                    ) : (
                                        form.loaded
                                            ? (
                                                <CustomMap
                                                    style={{
                                                        height: 700,
                                                        border: '1px solid #5ac8fa',
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        height: 700,
                                                        margin: 'auto',
                                                        border: '1px solid #5ac8fa',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >Не выбран адрес</div>
                                            )
                                    )
                            }
                        </Grid>
                        <Grid item xs={4} lg={4} sm={5} md={4}>
                            <StyledCard
                                style={{
                                    height: "auto"
                                }}
                            >
                                <Grid
                                    container
                                    direction={"column"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                    style={{
                                        maxWidth: 360,
                                        maxHeight: "75vh"
                                    }}
                                >
                                    <Grid item>
                                        <form
                                            noValidate
                                            style={{
                                                maxWidth: 360
                                            }}
                                        >
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledAutocomplete
                                                            noOptionsText="Адрес не найден"
                                                            id="name"
                                                            value={selectedAddress}
                                                            isOptionEqualToValue={(option: any, value: any) => {
                                                                return option.id === value.id;
                                                            }}
                                                            options={addresses}
                                                            getOptionLabel={(option: any) => option.label}
                                                            renderInput={(params) => (
                                                                <Tooltip title={form.name}>
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder="Адрес"
                                                                        label="Адрес"
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                            disabled={backurl !== defaultBackUrl}
                                                            onChange={handleFormChange}
                                                            onInputChange={(e, value) => {
                                                                if (backurl === defaultBackUrl) {
                                                                    $houseListStore.getData(value);
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledTextField
                                                            placeholder="Управляющая компания"
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            size="medium"
                                                            variant="outlined"
                                                            fullWidth
                                                            id="uk"
                                                            name="uk"
                                                            value={
                                                                form.uk === ''
                                                                    ? ''
                                                                    : getUKName(form.uk)
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledTextField
                                                            placeholder="Описание"
                                                            label="Описание"
                                                            size="medium"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="description"
                                                            id="description"
                                                            value={form.description}
                                                            onChange={(value) =>
                                                                setFormValue(
                                                                    "description",
                                                                    value.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                    {showButtons && (
                                        <Grid
                                            container
                                            item
                                            direction={"row"}
                                            spacing={2}
                                            justifyContent={"space-between"}
                                        >
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                    disabled={saving}
                                                >
                                                    Создать
                                                </ButtonSquare>
                                            </Grid>
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="outlined"
                                                    onClick={handleClick}
                                                    disabled={saving}
                                                >
                                                    Отмена
                                                </ButtonSquare>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </StyledCard>
                        </Grid>
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default AddNewZone;
