import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { $zoneTableDataStore } from "../../store/ZoneTableStore";
import { StyledCard } from "../../App";
import { useParams } from "react-router-dom";
import CustomMap from "../../components/Zone/Map";
import HighlightedZoneDetails from "../../components/Zone/HighlightedZoneDetails";
import MainContentWrap from "../../ui/Wrappers/MainContentWrap";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";

const ZoneDetail = observer(() => {
    const routeParams = useParams();
    const [loading, setLoading] = useState(true);
    const {
        selectedZoneData,
    } = $zoneTableDataStore;

    useEffect(() => {
        $zoneTableDataStore.setNewCoordinates(undefined);
        $zoneTableDataStore.getDetail(
            String(routeParams.guid)
        );
    }, [
        routeParams.guid,
    ]);

    useEffect(() => {
        if (selectedZoneData.guid !== '') {
            setLoading(false);
        }
    }, [
        selectedZoneData,
    ]);

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: '1em',
                        paddingLeft: '.75em',
                    }}
                >
                    <Grid item>
                        <Grid
                            container
                            direction={"row"}
                            spacing={2}
                            alignItems={"baseline"}
                        >
                            <Grid item>
                                <h3>Детали зоны {selectedZoneData?.id}</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"space-evenly"}
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={8}
                            lg={8}
                            sm={7}
                            md={8}
                        >
                            {
                                loading
                                    ? <CustomCircularProgress />
                                    : <CustomMap style={{
                                        height: 700,
                                    }}/>
                            }
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            lg={4}
                            sm={5}
                            md={4}
                        >
                            <HighlightedZoneDetails />
                        </Grid>
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default ZoneDetail;
