import {
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import {
    AddButton,
    SearchField,
} from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    ChangeEvent,
    useCallback,
    useEffect,
} from 'react';
import 'dayjs/locale/ru';
import { $anchorRefStore } from '../../../store/AnchorRef';
import { $contractsDataStore } from '../../../store/ContractsStore';
import { $loginUserStore } from '../../../store/ResponseData';
import EditNormativeData from './EditNormativeData';
import NormativeDataTableLine from './NormativeDataTableLine';

const NormativeData = observer(() => {
    const { normPage, normRowsPerPage, normSort, contractDetail, normSearch } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getNormList(
            String(contractDetail?.guid),
            String(normPage + 1),
            normSort.sort.by,
            normSort.sort.order,
            String(normRowsPerPage),
            normSearch
        );
    }, [
        contractDetail?.guid,
        normPage,
        normRowsPerPage,
        normSort.sort.by,
        normSort.sort.order,
        normSearch
    ]);

    const tableColumns = [
        {
            key: "id",
            label: "№ п/п",
            width: 100,
            hideSortIcon: true,
            show: true
        },
        {
            key: "period",
            label: "Период",
            width: 300,
            hideSortIcon: true,
            show: true
        },
        {
            key: "planWorkers",
            label: "норматив чел. план",
            width: 300,
            hideSortIcon: true,
            show: true
        },
        {
            key: "hoursWorker",
            label: "норматив чел/час план",
            width: 300,
            hideSortIcon: true,
            show: true
        },
        {
            key: "action",
            label: "Действия",
            width: 100,
            hideSortIcon: true,
            show: $loginUserStore.isAdmin()
        }
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setMembersSort({
                    sort: {
                        by: key,
                        order: normSort.sort.order === "asc" ? "desc" : "asc"
                    }
                });
            }
        },
        [normSort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $contractsDataStore.setNormPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $contractsDataStore.setNormRowsPerPage(parseInt(event.target.value, 10));
        $contractsDataStore.setNormPage(0);
    };

    const handleEditNorm = () => {
        $contractsDataStore.setEditNorm(true);
    };

    return (
        <>
            <Grid
                container
                justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}
            >
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleEditNorm}
                        // disabled={contractDetail?.available_users?.items.length === undefined}
                        style={{
                            margin: 0
                        }}
                    />
                ) : null}
                <SearchField
                    value={normSearch}
                    callback={(value) => $contractsDataStore.setNormSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    border: "1px solid #5ac8fa",
                    margin: "15px 0 0"
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((item: any) => {
                                if (!item.show) {
                                    return <></>;
                                }
                                return (
                                    <TableCell
                                        key={item.key}
                                        id={item.key}
                                        sortDirection={
                                            normSort.sort.by === item.key
                                                ? normSort.sort.order
                                                : false
                                        }
                                        style={{
                                            width: item.width,
                                            maxWidth: item.width
                                        }}
                                    >
                                        <TableSortLabel
                                            active={normSort.sort.by === item.key}
                                            hideSortIcon={item.hideSortIcon}
                                            direction={
                                                normSort.sort.by === item.key
                                                    ? normSort.sort.order
                                                    : "asc"
                                            }
                                            onClick={() => {
                                                if (!item.hideSortIcon) {
                                                    changeTableSort(item.key);
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <NormativeDataTableLine />
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={$contractsDataStore.normList?.pagination.total! ?? 0}
                    page={normPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={normRowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                    labelRowsPerPage="Cтрок на странице:"
                    style={{ display: "flex" }}
                />
            </TableContainer>
            <Menu
                disableScrollLock={true}
                anchorEl={$anchorRefStore.data}
                keepMounted
                open={Boolean($anchorRefStore.data)}
                onClose={() => {
                    $anchorRefStore.setAnchorRef(null);
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(null);
                        handleEditNorm();
                    }}
                >
                    Изменить
                </MenuItem>
            </Menu>
            {$contractsDataStore.editNorm && <EditNormativeData />}
        </>
    );
});
export default NormativeData;
