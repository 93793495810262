import axios from "../../axios";
import { toast } from "react-toastify";
import { Zone, ZoneList } from "../../types/zoneList";
import { Point as GeoJsonPoint } from "geojson";
import { NewZoneDetails } from "../../types/newZoneDetails";
import Notification from "../../components/Notification";
import { DEFAULT_COORDINATES } from "../../utils/const";

export async function getZoneList(
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    let data = {
        page: page,
        pageSize: pageSize,
        orderBy: orderBy,
        direction: direction,
        search: search,
    };
    let config = {
        method: "post",
        url: "/HouseService/Zones_list",
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: ZoneList = response.data.data;
        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }
        const items: Zone[] = result.items?.map((item: any) => {
            return {
                ...item,
                coordinates:
                    item.coordinates !== ""
                        ? (JSON.parse(item.coordinates) as GeoJsonPoint)
                        : (DEFAULT_COORDINATES as GeoJsonPoint),
            };
        });
        result = { ...result, items: items };

        if (!result) {
            Notification({
                title: 'Не удалось получить ответ',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список зон',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function saveZone(form: NewZoneDetails) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Zones/save",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newZone: Zone = response.data.data;
        if (!newZone) {
            Notification({
                title: 'Не удалось получить ответ',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newZone;
    } catch (error: any) {
        Notification({
            title: 'Не удалось загрузить данные',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getZoneDetail(
    guid: string
) {
    let config = {
        method: "get",
        url: "/HouseService/Zones_get?guid=" + guid,
    };
    try {
        const response = await axios.request(config);
        let result: Zone = response.data.data;

        if (!result) {
            Notification({
                title: 'Не удалось получить Геозону',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить Геозону',
            type: toast.TYPE.ERROR,
        });
    }
}