import { IconButton, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { $contractsDataStore } from "../../store/ContractsStore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AddressZone, contractAddress } from "../../types/contractAddress";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import { AppRoute, ContractRelationStatus } from "../../utils/const";
import { $anchorRefStore } from "../../store/AnchorRef";
import { generatePath, useNavigate } from "react-router-dom";
import { CustomCircularProgress } from "../CustomCircularProgress";
import { $loginUserStore } from "../../store/ResponseData";

const ContractCardAddressTableLine = observer(() => {
    let navigate = useNavigate();

    const {
        houseList,
        contractAddressRowsPerPage,
        contractAddressPage,
        contractDetail,
        contractAddressloadingTable,
    } = $contractsDataStore;

    if (contractDetail === undefined) {
        return <></>;
    }

    const counter = houseList?.items?.filter((item) => {
        return item.contract === contractDetail?.guid
    });

    if (contractAddressloadingTable || counter === undefined) {
        return <TableRow>
            <TableCell
                colSpan={7}
            >
                <CustomCircularProgress />
            </TableCell>
        </TableRow>;
    }

    if (counter.length <= 0) {
        return <TableRow>
            <TableCell
                colSpan={7}
                align="center"
            >
                Нет добавленных адресов
            </TableCell>
        </TableRow>;
    }

    return (
        <>
            {houseList?.items?.map(
                (tableLine: contractAddress, index) => {
                    return (
                        <TableRow
                            key={tableLine.guid}
                            component={"tr"}
                            // sx={{
                            //     position: "relative",
                            //     "&:hover": {
                            //         backgroundColor: "#f8f8f8 !important",
                            //         boxShadow:
                            //             "inset 0 0 4px rgba(0, 0, 0, 0.06)",
                            //     },
                            // }}
                        >
                            <TableCell style={{ textWrap: "nowrap" }}>{
                                (contractAddressRowsPerPage * contractAddressPage) +
                                index + 1
                            }</TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {tableLine.name}
                            </TableCell>
                            <TableCell><div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}>{
                                tableLine.zones.items.length !== undefined
                                    ? tableLine.zones.items.map(
                                        (zone: AddressZone) => {
                                            return <Button
                                                component="label"
                                                role={undefined}
                                                variant="text"
                                                tabIndex={-1}
                                                endIcon={
                                                    <OpenInNewIcon style={{
                                                        width: 12,
                                                        height: 12,
                                                    }} />
                                                }
                                                style={{
                                                    fontSize: 12,
                                                    padding: 0,
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        generatePath(AppRoute.ZoneDetail, {
                                                            guid: String(zone.guid),
                                                        })
                                                    );
                                                }}
                                            >
                                                {zone.id}
                                            </Button>
                                        })
                                    : $loginUserStore.isAdmin()
                                        ? <Button
                                            component="label"
                                            role={undefined}
                                            variant="text"
                                            tabIndex={-1}
                                            endIcon={
                                                <OpenInNewIcon style={{
                                                    width: 12,
                                                    height: 12,
                                                }} />
                                            }
                                            style={{
                                                fontSize: 12,
                                                padding: 0,
                                                textTransform: 'unset',
                                                color: 'rgba(255, 0, 0, 0.75)',
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    generatePath(AppRoute.ZoneNew) + '?' +
                                                    new URLSearchParams({
                                                        house: tableLine.house,
                                                        name: tableLine.name,
                                                        backurl: generatePath(AppRoute.ContractDetail, {
                                                            guid: tableLine.contract,
                                                        })
                                                    }).toString()
                                                );
                                            }}
                                        >
                                            Создать
                                        </Button>
                                        : null
                            }</div></TableCell>
                            <TableCell>
                                {/*{formatDate(tableLine.start)}*/}
                            </TableCell>
                            <TableCell>
                                {/*{formatDate(tableLine.end)}*/}
                            </TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>{
                                tableLine?.status
                                    ? ContractRelationStatus[ tableLine?.status as keyof typeof ContractRelationStatus ]
                                    : null
                            }</TableCell>
                            {
                                $loginUserStore.isAdmin()
                                    ? <TableCell>
                                        <IconButton onClick={(event) => {
                                            $anchorRefStore.setAnchorRef(event.currentTarget);
                                            $contractsDataStore.setAddressEditData(tableLine);
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </TableCell>
                                    : null
                            }
                        </TableRow>
                    );
                }
            )}
        </>
    );
});

export default ContractCardAddressTableLine;
