import { Link } from "react-router-dom";
import { AppRoute } from "../utils/const";
import { Grid } from "@mui/material";
import { StyledCard } from "../App";

function NotFound() {
  return (<StyledCard>
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "75vh",
      }}
    >
      <h1 style={{ color: "red" }}>
        404
        <br />
        <small> NOT FOUND</small>
      </h1>
      <Grid item>
        <Link to={AppRoute.Root}>
          <h3>Вернуться на главный экран</h3>
        </Link>
      </Grid>
    </Grid>
  </StyledCard>);
}

export default NotFound;
