import React, { ReactNode } from "react";
import { TableCell, TableRow, TableCellProps, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import { $membersDataStore } from "../../store/MembersStore";
import { $anchorRefStore } from "../../store/AnchorRef";
import { MoreHoriz } from "@mui/icons-material";
import { Member, Role } from "../../types/member";

interface CustomTableCellProps extends TableCellProps {
    children: ReactNode;
    keyCell: string;
}

const MemberTableLine = observer(() => {
    const { tableColumns } = $membersDataStore;

    const CustomTableCell: React.FC<CustomTableCellProps> = ({ children, keyCell, ...props }) => {
        const column = tableColumns.find((el) => el.key === keyCell);
        return column ? <TableCell {...props}>{children}</TableCell> : null;
    };

    if (
        $membersDataStore.membersData?.items.length === undefined ||
        $membersDataStore.membersData?.items.length === 0
    ) {
        return (
            <TableRow>
                <TableCell colSpan={9} align="center">
                    Нет пользователей
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            {$membersDataStore.membersData?.items.map((tableLine: Member) => {
                return (
                    <TableRow
                        key={tableLine.guid}
                        component={"tr"}
                        sx={{
                            position: "relative",
                            "&:hover": {
                                backgroundColor: "#f8f8f8 !important",
                                boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.06)"
                            }
                        }}
                    >
                        <CustomTableCell
                            keyCell="name"
                            style={{
                                textWrap: "nowrap"
                            }}
                        >
                            {tableLine.name}
                        </CustomTableCell>
                        <CustomTableCell keyCell="organisation" style={{ textWrap: "nowrap" }}>
                            {tableLine.organisation.name}
                        </CustomTableCell>
                        <CustomTableCell keyCell="roles" style={{ textWrap: "nowrap" }}>
                            {tableLine.roles?.items.map((role: Role) => role.name).join(", ")}
                        </CustomTableCell>
                        <CustomTableCell keyCell="action" style={{ textAlign: "center" }}>
                            <IconButton
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(event.currentTarget);
                                    $membersDataStore.setMemberData(tableLine);
                                }}
                            >
                                <MoreHoriz />
                            </IconButton>
                        </CustomTableCell>
                    </TableRow>
                );
            })}
        </>
    );
});

export default MemberTableLine;
