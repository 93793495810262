import { Button, Grid, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField/TextField";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { UserData } from "../../types/user.data";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockIcon from "@mui/icons-material/Lock";
import Divider from "@mui/material/Divider";
import { $loginUserStore } from "../../store/ResponseData";
import { loginUser } from "./LoginApi";
import map from "../../icons/map.svg";
import { observer } from "mobx-react";

const StyledTextField = styled(TextField)({
    margin: "20px 0 10px",
    borderRadius: 15,
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: 15,
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: 15,
        },
        "& input": {
            filter: 'none',
            borderRadius: '0 15px 15px 0',
            paddingLeft: 5,
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const StyledButton = styled(Button)({
    background: "#20a0ff",
    borderRadius: "40px",
    fontFamily: "ProximaNova",
    height: "45px",
    width: "100px",
    color: "#fff",
    marginRight: "73%",
    marginTop: "20px",
    textTransform: "initial",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.25px",
    fontWeight: "600",
});

const Login = observer((): JSX.Element => {
    const [form, setForm] = useState<UserData>({ login: "", password: "" });
    const [validationErrors, setValidationErrors] = useState<UserData>({
        login: "",
        password: "",
    });

    const emptyValidationErrors = useMemo(() => {
        return { login: "", password: "" };
    }, []);

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
            setValidationErrors({ ...validationErrors, [name]: "" });
        },
        [form, validationErrors]
    );

    const onSubmit = useCallback(
        async (
            e: FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>
        ) => {
            e.preventDefault();
            setValidationErrors(emptyValidationErrors);
            const response = await loginUser(form);
            if (!response) return;
            $loginUserStore.setData(response.data);
        },
        [emptyValidationErrors, form]
    );

    const inputKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                onSubmit(e);
            }
        },
        [onSubmit]
    );

    const isValid = useMemo(() => {
        return form.login.match(/[^\s]/gm) && form.password.match(/[^\s]/gm);
    }, [form.login, form.password]);

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                height: "calc(100vh - 30px)",
            }}
        >
            <Grid item xs={3}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        maxWidth: 300,
                        minWidth: 300,
                        margin: "0 auto",
                    }}
                >
                    <h2>Добро пожаловать!</h2>
                    <form onSubmit={onSubmit} noValidate>
                        <StyledTextField
                            size="medium"
                            variant="outlined"
                            required
                            fullWidth
                            placeholder="Логин"
                            name="login"
                            id="login"
                            onKeyUp={inputKeyPress}
                            value={form.login}
                            onChange={(value) =>
                                setFormValue("login", value.target.value)
                            }
                            error={validationErrors.login.length > 0}
                            helperText={
                                validationErrors.login.length > 0
                                    ? validationErrors.login
                                    : false
                            }
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <AccountBoxIcon />
                                        </InputAdornment>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                border: "1px solid #5ac8fa",
                                            }}
                                        />
                                    </>
                                ),
                            }}
                        />

                        <StyledTextField
                            size="medium"
                            variant="outlined"
                            required
                            fullWidth
                            type="password"
                            placeholder="Пароль"
                            name="password"
                            id="password"
                            onKeyUp={inputKeyPress}
                            value={form.password}
                            onChange={(value) =>
                                setFormValue("password", value.target.value)
                            }
                            error={validationErrors.password.length > 0}
                            helperText={
                                validationErrors.password.length > 0
                                    ? validationErrors.password
                                    : false
                            }
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                border: "1px solid #5ac8fa",
                                            }}
                                        />
                                    </>
                                ),
                            }}
                        />

                        <StyledButton
                            type="submit"
                            variant="contained"
                            disabled={!isValid}
                            style={{ width: "100%" }}
                        >
                            Войти
                        </StyledButton>
                    </form>
                </Grid>
            </Grid>
            <Grid item xs={9} style={{ background: "#fff" }}>
                <img
                    src={map}
                    alt=""
                    style={{
                        width: "70vw",
                        borderLeft: "1px solid #5ac8fa",
                        height: "100%",
                        maxHeight: "100vh",
                        minHeight: "100vh",
                        margin: -15,
                    }}
                />
            </Grid>
        </Grid>
    );
});

export default Login;
