import axios from "../../axios";
import { toast } from "react-toastify";
import { ContractInfo, ContractList } from "../../types/contractListInfo";
import { NewContract } from "../../types/newContract";
import { contractAddress, contractAddressList, } from "../../types/contractAddress";
import { contractMember, contractMembersList, } from "../../types/contractMember";
import { CompletedWork, CompletedWorkList } from "../../types/completedWorks";
import { NormItem, NormItemList } from "../../types/normativeData";
import Notification from "../../components/Notification";

export async function getContractListInfo(
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    let data = {
        page: page,
        pageSize: pageSize,
        orderBy: orderBy,
        direction: direction,
        search: search,
    };
    let config = {
        method: "post",
        url: "/HouseService/Contracts_list",
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: ContractList = response.data.data;

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        if (!result) {
            Notification({
                title: 'Не удалось получить список договоров',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список договоров',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getContractDetail(
    guid: string
) {
    let config = {
        method: "get",
        url: "/HouseService/Contracts_get?guid=" + guid,
    };
    try {
        const response = await axios.request(config);
        let result: ContractInfo = response.data.data;

        if (!result) {
            Notification({
                title: 'Не удалось получить договор',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить договор',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function newContractCreate(form: NewContract) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_save",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newContract: ContractInfo = response.data.data;
        if (!newContract) {
            Notification({
                title: 'Не удалось создать договор',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newContract;
    } catch (error: any) {
        Notification({
            title: 'Не удалось создать договор',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getHouseListByContract(
    contract: string,
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    if (contract === 'undefined') {
        return;
    }
    let data = {
        contract: contract,
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search,
    };
    let config = {
        method: "post",
        url: `/HouseService/Contracts_houses`,
        data: data,
    };
    try {
        const response = await axios.request(config);

        let result: contractAddressList = response.data.data;

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список адресов для договора',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function addContractHouse(form: contractAddress) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_saveHouse",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newContract: ContractInfo = response.data.data;
        if (!newContract) {
            Notification({
                title: 'Не удалось привязать дом',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newContract;
    } catch (error: any) {
        Notification({
            title: 'Не удалось привязать дом',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function addContractHouseList(form: contractAddress) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_saveHouseList",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newContract: ContractInfo = response.data.data;
        if (!newContract) {
            Notification({
                title: 'Не удалось привязать дома',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newContract;
    } catch (error: any) {
        Notification({
            title: 'Не удалось привязать дома',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getMembersByContract(
    contract: string,
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    if (contract === 'undefined') {
        return;
    }
    let data = {
        contract: contract,
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search
    };
    let config = {
        method: "post",
        url: `/HouseService/Contracts_users`,
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: contractMembersList = response.data.data;

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        return result;
    } catch (error) {
        console.log(error);
        Notification({
            title: 'Не удалось получить список сотрудников для договора',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function addContractUser(form: contractMember) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_saveUser",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newContract: ContractInfo = response.data.data;
        if (!newContract) {
            Notification({
                title: 'Не удалось привязать дом',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newContract;
    } catch (error: any) {
        Notification({
            title: 'Не удалось привязать дом',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getContractCompletedWork(guid: string) {
    let data = { guid };
    let config = {
        method: "post",
        url: "/HouseService/Contracts_getWork",
        data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newWork: CompletedWork = response.data.data;
        if (!newWork) {
            Notification({
                title: "Не удалось получить данные по акту выполненных работ",
                type: toast.TYPE.ERROR
            });
            return;
        }
        return newWork;
    } catch (error: any) {
        Notification({
            title: "Не удалось получить данные по акту выполненных работ",
            type: toast.TYPE.ERROR
        });
    }
}

export async function getCompletedWorkListByContract(
    contract: string,
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    if (contract === 'undefined') {
        return;
    }
    let data = {
        contract: contract,
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search
    };
    let config = {
        method: "post",
        url: `/HouseService/Contracts_workList`,
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: CompletedWorkList = response.data.data;

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        return result;
    } catch (error) {
        console.log(error);
        Notification({
            title: 'Не удалось получить список актов выполненных работ по договору',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function editContractCompletedWork(form: CompletedWork) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_saveWork",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newWork: CompletedWork = response.data.data;
        if (!newWork) {
            Notification({
                title: 'Не удалось сохранить акт выполненных работ',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newWork;
    } catch (error: any) {
        Notification({
            title: 'Не удалось сохранить акт выполненных работ',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function getContractNorm(guid: string) {
    let data = { guid };
    let config = {
        method: "post",
        url: "/HouseService/Contracts_getNorm",
        data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newNorm: NormItem = response.data.data;
        if (!newNorm) {
            Notification({
                title: "Не удалось получить норматив",
                type: toast.TYPE.ERROR
            });
            return;
        }
        return newNorm;
    } catch (error: any) {
        Notification({
            title: "Не удалось получить норматив",
            type: toast.TYPE.ERROR
        });
    }
}

export async function getNormListByContract(
    contract: string,
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search: string
) {
    if (contract === 'undefined') {
        return;
    }
    let data = {
        contract: contract,
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search
    };
    let config = {
        method: "post",
        url: `/HouseService/Contracts_normList`,
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: NormItemList = response.data.data;

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        return result;
    } catch (error) {
        console.log(error);
        Notification({
            title: 'Не удалось получить список нормотивов по договору',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function editContractNorm(form: NormItem) {
    let data = form;
    let config = {
        method: "post",
        url: "/HouseService/Contracts_saveNorm",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newWork: CompletedWork = response.data.data;
        if (!newWork) {
            Notification({
                title: 'Не удалось сохранить норматив',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return newWork;
    } catch (error: any) {
        Notification({
            title: 'Не удалось сохранить норматив',
            type: toast.TYPE.ERROR,
        });
    }
}