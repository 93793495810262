import Grid from "@mui/material/Grid";
import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import logo from "../icons/logo.png";
import { $loginUserStore } from "../store/ResponseData";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useCallback, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getIcon, mockMenu } from "../utils/utils";
import { $mainMenuStore } from "../store/MenuStore";
import { AppRoute } from "../utils/const";
import AvatarClickCard from "./AvatarClickCard";
import { $zoneTableDataStore } from "../store/ZoneTableStore";

const StyledMenu = styled(Box)({
    background: "white",
    padding: 0,
    height: "100%",
    boxShadow: "5px 0px 10px -3px rgba(0, 0, 0, 0.2)",
});

const StyledList = styled(List)({
    background: "white",
    padding: 0,
    height: "auto",
});

const StyledListItem = styled(ListItem)({
    backgroundColor: '"#fff"',
    paddingLeft: 0,
    paddingRight: 0,
    height: 55,
    "&:hover": {
        backgroundColor: "#eef1f6!important",
        color: "black"
    }
});

const StyledListItemText = styled(ListItemText)({
    height: 55,
    margin: 0,
    width: '100%',
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    "& > span": {
        lineHeight: "16px"
    }
});

const StyledTypography = styled(Typography)({
    height: 55,
    "& > a": {
        color: "#20a0ff",
        textDecoration: "none",
        "&:hover": {
            color: "#fff"
        },
        "&.active > .MuiListItem-root": {
            backgroundColor: "#20a0ff!important",
            color: "#fff"
        }
    }
});

const MainMenu = observer(() => {
    const location = useLocation();
    const [clickedAvatar, setClickedAvatar] = useState(false);

    const {
        menuStatus,
    } = $mainMenuStore;

    const onToggleMenuButton = useCallback(() => {
        $mainMenuStore.setMenuStatus(!menuStatus);
    }, [menuStatus]);

    const handleClick = useCallback((id: number, name: string) => {
        $zoneTableDataStore.setShowZoneDetailes(false);
    }, []);

    return (
        <StyledMenu>
            <Grid
                container
                mt={0}
                direction={"column"}
                alignItems={menuStatus ? "flex-start" : "center"}
                style={{
                    position: 'sticky',
                    top: 0,
                }}
            >
                <Grid
                    container
                    item
                    direction={"row"}
                    justifyContent={menuStatus ? "space-around" : "center"}
                    alignItems={"center"}
                    sx={{
                        height: "70px"
                    }}
                >
                    <Grid item>
                        {!menuStatus ? null : (
                            <Link to={AppRoute.Root}>
                                <img src={logo} alt="" />
                            </Link>
                        )}
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onToggleMenuButton}>
                            {menuStatus ? (
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.8 8.8L15.2 8.8M15.2 8.8V3.2M15.2 8.8L21.6 2.4M8.79999 20.8L8.79999 15.2M8.79999 15.2L3.19999 15.2M8.79999 15.2L2.39999 21.6"
                                        stroke="#20A0FF"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2 9.98V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22H14"
                                        stroke="#20A0FF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13 11L18.01 5.98H14"
                                        stroke="#20A0FF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M18.01 5.98V9.99"
                                        stroke="#20A0FF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M11 16.15V18.85C11 21.1 10.1 22 7.85 22H5.15C2.9 22 2 21.1 2 18.85V16.15C2 13.9 2.9 13 5.15 13H7.85C10.1 13 11 13.9 11 16.15Z"
                                        stroke="#20A0FF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
                {!menuStatus ? null : <Divider flexItem />}
                <Grid container item py={2}>
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent={menuStatus ? "left" : "center"}
                        alignItems="center"
                        flexWrap="nowrap"
                    >
                        <Grid item style={{marginLeft: menuStatus ? 10 : 0}}>
                            <ClickAwayListener
                                onClickAway={() => {
                                    if (clickedAvatar) {
                                        setClickedAvatar(false);
                                    }
                                }}
                            >
                                <Button
                                    style={{
                                        display: "flex",
                                        width: "60px",
                                        height: "60px",
                                        border: "1px solid #5ac8fa",
                                        borderRadius: "50%"
                                    }}
                                    onClick={() =>
                                        setClickedAvatar((clickedAvatar: boolean) => !clickedAvatar)
                                    }
                                >
                                    <p
                                        style={{
                                            textAlign: "center",
                                            margin: "auto"
                                        }}
                                    >
                                        {$loginUserStore.data?.name
                                            .split(" ")
                                            .map(function (item) {
                                                return item[0];
                                            })
                                            ?.join("")
                                            .toUpperCase()}
                                    </p>
                                </Button>
                            </ClickAwayListener>
                        </Grid>
                        {menuStatus ? (
                            <Grid item style={{marginLeft: 10}}>
                                <p style={{margin: 0, fontSize: "14px"}}>
                                    <b>{$loginUserStore.data?.name}</b>
                                </p>
                                <p style={{margin: 0, fontSize: "14px"}}>
                                    {$loginUserStore.data?.organisation?.name}
                                </p>
                                {
                                    $loginUserStore.data?.roles.items.length
                                        ? (<p style={{margin: 0, fontSize: "14px"}}>
                                            {'Роль: ' + $loginUserStore.data?.roles.items.map((role) => role.name).join(", ")}
                                        </p>)
                                        : null
                                }                                
                                <p style={{margin: 0, fontSize: "14px"}}>
                                    ID: {$loginUserStore.data?.id}
                                </p>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                {!menuStatus ? null : <Divider flexItem/>}
                <Grid
                    item
                    style={{
                        width: "100%"
                    }}
                >
                    <StyledList>
                        {mockMenu?.map((mockMenu, index) => {
                            return (
                                <React.Fragment key={"menu-item-" + index}>
                                    <StyledTypography variant="body1" key={"li-" + index}>
                                        <NavLink
                                            style={{
                                                display: "flex",
                                                margin: "15px auto",
                                                width: "100%"
                                            }}
                                            className={
                                                location.pathname === mockMenu.url ? "active" : ""
                                            }
                                            to={mockMenu.url}
                                            onClick={() => handleClick(mockMenu.id, mockMenu.name)}
                                        >
                                            <StyledListItem
                                                key={"menu-row-" + index}
                                                style={{
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        "&:hover, &.Mui-focusVisible": {
                                                            background: "none"
                                                        },
                                                        marginLeft: menuStatus
                                                            ? '10px'
                                                            : 0
                                                    }}
                                                >
                                                    {getIcon(mockMenu.icon)}
                                                </IconButton>
                                                {menuStatus && (
                                                    <>
                                                        <StyledListItemText
                                                            primary={mockMenu.name}
                                                        />
                                                        {mockMenu.collapsed && (
                                                            <IconButton
                                                                sx={{
                                                                    "&:hover, &.Mui-focusVisible": {
                                                                        background: "none"
                                                                    }
                                                                }}
                                                            >
                                                                <ArrowDropDownIcon />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )}
                                            </StyledListItem>
                                        </NavLink>
                                    </StyledTypography>
                                </React.Fragment>
                            );
                        })}
                    </StyledList>
                </Grid>
            </Grid>
            {clickedAvatar && <AvatarClickCard />}
        </StyledMenu>
    );
});

export default MainMenu;
