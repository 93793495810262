import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Typography } from "@mui/material";
import { TypeOptions } from "react-toastify/dist/types";

type NotificationProp = {
    title?: string,
    message?: string,
    type?: TypeOptions,
    duration?: number,
}

const DEFAULT_DURATION = 30000

export default function Notification(props: NotificationProp) {
    const newlineText = (text: string) => {
        return text.split('\n').map((str, i) => <p key={i}>{str}</p>);
    };

    toast(
        <div style={{flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs style={{margin: '-1px 0 -1px 4px'}}>
                            {props.title ? (
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 'bolder',
                                        color: '#000',
                                    }}
                                >
                                    {props.title}
                                </Typography>) : null}
                            {props.message ? (
                                <Typography
                                    variant="body2"
                                    component="span"
                                    gutterBottom
                                    style={{
                                        lineHeight: '18px',
                                        marginTop: '4px',
                                    }}
                                >
                                    {newlineText(props.message)}
                                </Typography>) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        , {
            type: props.type,
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: props.duration ?? DEFAULT_DURATION,
            rtl: false
        });
}
