import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import {
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import { $membersDataStore } from "../../store/MembersStore";
import { SearchField } from "@synapse/frontend-react";
import { StyledCard } from "../../App";
import MainContentWrap from "../../ui/Wrappers/MainContentWrap";
import MemberTableLine from "../../components/Members/MemberTableLine";
import { $loginUserStore } from "../../store/ResponseData";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";
import { $anchorRefStore } from "../../store/AnchorRef";
import EditRoleDialog from "../../components/Members/editRoleDialog";
import AttachUkDialog from "../../components/Members/AttachUkDialog";

const Members = observer(() => {
    const {
        membersPage,
        membersRowsPerPage,
        membersloadingTable,
        membersSort,
        membersSearch,
        membersData,
        organisation,
        tableColumns,
        memberData
    } = $membersDataStore;

    const tableColumnsInit = [
        {
            key: "name",
            label: "ФИО сотрудника",
            width: 300
        },
        {
            key: "organisation",
            label: "Организация",
            width: 300
        },
        {
            key: "roles",
            label: "Роль",
            width: 100
        },
        {
            key: "action",
            label: "Действия",
            width: 60,
            align: "end"
        }
    ];

    useEffect(() => {
        let tableColumns = tableColumnsInit;

        // Если это не админ, убираем лишние столбцы
        if (!$loginUserStore.isAdmin()) {
            tableColumns = tableColumns.filter((column) => !["action", "roles"].includes(column.key));
        }

        $membersDataStore.setTableColumns(tableColumns);
        $membersDataStore.getRoleList();
    }, []);

    useEffect(() => {
        $membersDataStore.getData(
            String(membersPage + 1),
            membersSort.sort.by,
            membersSort.sort.order,
            String(membersRowsPerPage),
            membersSearch,
            organisation
        );
    }, [membersSearch, membersPage, membersRowsPerPage, membersSort, organisation]);

    const changeTableSort = useCallback(
        (key: string) => {
            $membersDataStore.setSort({
                sort: {
                    by: key,
                    order:
                        membersSort.sort.by !== key
                            ? "asc"
                            : membersSort.sort.order === "asc"
                            ? "desc"
                            : "asc"
                }
            });
        },
        [membersSort]
    );

    const handleChangePage = (newPage: number) => {
        $membersDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $membersDataStore.setRowsPerPage(v);
        $membersDataStore.setPage(0);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Сотрудники</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={membersSearch}
                            callback={(value) => $membersDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer
                        component={Paper}
                        style={{
                            border: "1px solid #5ac8fa",
                            margin: 0
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((item: any) => {
                                        return (
                                            <TableCell
                                                key={item.key}
                                                id={item.key}
                                                style={{
                                                    width: item.width,
                                                    maxWidth: item.width,
                                                    textAlign: item.align
                                                }}
                                                sortDirection={
                                                    membersSort.sort.by === item.key
                                                        ? membersSort.sort.order
                                                        : false
                                                }
                                            >
                                                <TableSortLabel
                                                    hideSortIcon={item.hideSortIcon}
                                                    direction={
                                                        membersSort.sort.by === item.key
                                                            ? membersSort.sort.order
                                                            : "asc"
                                                    }
                                                    onClick={() => {
                                                        if (!item.hideSortIcon) {
                                                            changeTableSort(item.key);
                                                        }
                                                    }}
                                                    active={membersSort.sort.by === item.key}
                                                >
                                                    {item.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {membersloadingTable ? (
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <CustomCircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <MemberTableLine />
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={membersData?.pagination?.total! ?? 0}
                            page={membersPage}
                            onPageChange={(e, newPage) => handleChangePage(newPage)}
                            rowsPerPage={membersRowsPerPage}
                            onRowsPerPageChange={(event) =>
                                handleChangeRowsPerPage(parseInt(event.target.value))
                            }
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} из ${count}`
                            }
                            labelRowsPerPage="Cтрок на странице:"
                            style={{ display: "flex" }}
                        />
                    </TableContainer>
                    <Menu
                        disableScrollLock={true}
                        anchorEl={$anchorRefStore.data}
                        keepMounted
                        open={Boolean($anchorRefStore.data)}
                        onClose={() => {
                            $anchorRefStore.setAnchorRef(null);
                        }}
                    >                      
                        {$loginUserStore.isAdmin() ? (
                            <MenuItem
                                onClick={(event) => {
                                    console.log($anchorRefStore.data)
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setOnEditRoleDialog(true);
                                }}
                            >
                                Редактировать права
                            </MenuItem>
                        ) : null}
                         {$loginUserStore.isAdmin() && memberData.roles?.items.find(el => el.name === 'Руководитель региона') ? (
                            <MenuItem
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setOnAttachUkDialog(true);
                                }}
                            >
                                Прикрепить УК
                            </MenuItem>
                        ) : null}
                    </Menu>
                    {($membersDataStore.onEditRoleDialog) && (
                        <EditRoleDialog />
                    )}
                     {($membersDataStore.onAttachUkDialog) && (
                        <AttachUkDialog />
                    )}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Members;
