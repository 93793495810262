import { makeAutoObservable } from "mobx";
import {
    MarkerInfo,
    MonitoringCoordinates,
    MonitoringFilterData,
    MonitoringFilters,
    MonitoringItem,
    MonitoringSelectedOptions,
    MonitoringUsersData
} from "../types/monitoring";
import { getMonitoringData, getMonitoringFilterInfo } from "../Pages/Monitoring/MonitoringApi";
import throttle from "lodash.throttle";
import { FeatureCollection } from "geojson";
import { DEFAULT_COORDINATES } from "../utils/const";
import { Marker } from "leaflet";
import { toast } from "react-toastify";
import Notification from "../components/Notification";

class MonitoringStore {
    selectedOptions: MonitoringSelectedOptions = {
        city: [],
        uk: [],
        organisation: [],
        contract: [],
        user: []
    };

    initialFilters = {
        city: "",
        uk: "",
        organisation: "",
        contract: "",
        user: "",
        date: new Date(),
        show_routes: 0,
        show_zones: 0,
        noise_filter: 0
    };

    initialfilterData = {
        city: { items: [] },
        uk: { items: [] },
        organisation: { items: [] },
        contract: { items: [] },
        user: { items: [] }
    };

    disableChange = {
        city: false,
        uk: false,
        organisation: false,
        contract: false,
    };

    filterData: MonitoringFilterData = this.initialfilterData;
    filters: MonitoringFilters = this.initialFilters;
    usersDrawerIsOpen?: boolean = false;
    mapData?: FeatureCollection;
    usersData: MonitoringUsersData = {};
    loadingUsersData: boolean = false;
    drawerWidth: number = 500;
    mapCenter: MonitoringCoordinates = DEFAULT_COORDINATES.coordinates;
    updateBounds: boolean = true;
    Markers: MarkerInfo[] = [];
    currentMarker?: Marker | null;

    constructor() {
        makeAutoObservable(this);
    }

    toggleDrawer() {
        this.usersDrawerIsOpen = !this.usersDrawerIsOpen;
    }

    toggleShowZones() {
        const newValue = this.filters.show_zones ^ 1;
        this.filters = {
            ...this.filters,
            show_zones: newValue
        };
        this.updateBounds = false;
    }

    toggleShowRoutes() {
        const newValue = this.filters.show_routes ^ 1;
        this.filters = {
            ...this.filters,
            show_routes: newValue
        };
        this.updateBounds = false;
    }

    toggleNoiseFilter() {
        const newValue = this.filters.noise_filter ^ 1;
        this.filters = {
            ...this.filters,
            noise_filter: newValue
        };
        this.updateBounds = false;
    }

    setFilterDate(value: string) {
        this.filters = {
            ...this.filters,
            date: value           
        };

        this.updateBounds = true;
    }

    setFilterOption(name: string, value: string) {
        switch (name) {
            case "city":
                this.filters = {
                    ...this.filters,
                    city: value,
                    uk: this.initialFilters.uk,
                    organisation: this.initialFilters.organisation,
                    contract: this.initialFilters.contract,
                    user: this.initialFilters.user
                };
                this.filterData = {
                    ...this.filterData,
                    uk: this.initialfilterData.uk,
                    organisation: this.initialfilterData.organisation,
                    contract: this.initialfilterData.contract,
                    user: this.initialfilterData.user
                };
                break;
            case "uk":
                this.filters = {
                    ...this.filters,
                    uk: value,
                    organisation: this.initialFilters.organisation,
                    contract: this.initialFilters.contract,
                    user: this.initialFilters.user
                };
                this.filterData = {
                    ...this.filterData,
                    organisation: this.initialfilterData.organisation,
                    contract: this.initialfilterData.contract,
                    user: this.initialfilterData.user
                };
                break;
            case "organisation":
                this.filters = {
                    ...this.filters,
                    organisation: value,
                    contract: this.initialFilters.contract,
                    user: this.initialFilters.user
                };
                this.filterData = {
                    ...this.filterData,
                    contract: this.initialfilterData.contract,
                    user: this.initialfilterData.user
                };
                break;
            case "contract":
                this.filters = {
                    ...this.filters,
                    contract: value,
                    user: this.initialFilters.user
                };
                this.filterData = {
                    ...this.filterData,
                    user: this.initialfilterData.user
                };
                break;
            case "user":
                this.filters = {
                    ...this.filters,
                    user: value
                };
                break;
            default:
                this.filters = this.initialFilters;
                break;
        }
        this.updateBounds = true;
    }

    setSelectedOption(name: string, value: MonitoringItem[]) {
        switch (name) {
            case "city":
                this.selectedOptions = {
                    city: value,
                    uk: [],
                    organisation: [],
                    contract: [],
                    user: []
                };
                break;
            case "uk":
                this.selectedOptions = {
                    ...this.selectedOptions,
                    uk: value,
                    organisation: [],
                    contract: [],
                    user: []
                };
                break;
            case "organisation":
                this.selectedOptions = {
                    ...this.selectedOptions,
                    organisation: value,
                    contract: [],
                    user: []
                };
                break;
            case "contract":
                this.selectedOptions = {
                    ...this.selectedOptions,
                    contract: value,
                    user: []
                };
                break;
            case "user":
                this.selectedOptions = {
                    ...this.selectedOptions,
                    user: value
                };
                break;
            default:
                this.selectedOptions = {
                    city: [],
                    uk: [],
                    organisation: [],
                    contract: [],
                    user: []
                };
                break;
        }
        this.updateBounds = true;
    }

    getFilterInfo(filters: MonitoringFilters) {
        this.setLoadingUsersData(true);
        const responseInfo = async () => {
            return await getMonitoringFilterInfo(filters);
        };
        const throttledInfo = throttle(responseInfo, 100);
        throttledInfo()?.then((res) => {
            if (res) {
                this.setFilterInfo(res);
            }
        });

        const responseData = async () => {
            return await getMonitoringData(filters);
        };

        const throttledMapData = throttle(responseData, 100);
        throttledMapData()?.then((res) => {
            if (res) {
                const map = JSON.parse(res.map) as FeatureCollection;
                const table = JSON.parse(res.table);
                this.setMapData(map);
                this.setUsersData(table);
                this.setLoadingUsersData(false);
                const lines = map.features.filter((feature) => feature?.properties?.type === "point");
                if (lines.length === 0) {
                    Notification({
                        title: "Нет данных для отображения",
                        type: toast.TYPE.INFO,
                        duration: 3000
                    });
                }
            }
        });
    }

    setFilterInfo(data: MonitoringFilterData) {
        if (!this.filterData.uk.items.length && data.uk?.items.length) {
            this.filterData.uk = data.uk;
            this.filterData.organisation = this.initialfilterData.organisation;
            this.filterData.contract = this.initialfilterData.contract;
            this.filterData.user = this.initialfilterData.user;

            if (data.uk.items.length === 1) {
                const uks = data.uk.items.map((item) => item.id).join(",");
                this.setFilterOption('uk', uks);
                this.setSelectedOption('uk', data.uk.items);
                this.disableChange.uk = true;
            } else {
                this.disableChange.uk = false;
            }
        }

        if (!this.filterData.organisation?.items.length && data.organisation?.items.length) {
            this.filterData.organisation = data.organisation;
            this.filterData.contract = this.initialfilterData.contract;
            this.filterData.user = this.initialfilterData.user;

            if (data.organisation.items.length === 1) {
                const organisations = data.organisation.items.map((item) => item.id).join(",");
                this.setFilterOption('organisation', organisations);
                this.setSelectedOption('organisation', data.organisation.items);
                this.disableChange.organisation = true;
            } else {
                this.disableChange.organisation = false;
            }
        }

        if (!this.filterData.contract?.items.length && data.contract?.items.length) {
            this.filterData.contract = data.contract;
            this.filterData.user = this.initialfilterData.user;

            if (data.contract.items.length === 1) {
                const contracts = data.contract.items.map((item) => item.id).join(",");
                this.setFilterOption('contract', contracts);
                this.setSelectedOption('contract', data.contract.items);
                this.disableChange.contract = true;
            } else {
                this.disableChange.contract = false;
            }
        }

        if (!this.filterData.user?.items.length && data.user?.items.length) {
            this.filterData.user = data.user;
        }
    }

    setMapData(data: FeatureCollection) {
        this.mapData = data;
    }

    setUsersData(data: MonitoringUsersData) {
        this.usersData = data;
    }

    setLoadingUsersData(status: boolean) {
        this.loadingUsersData = status;
    }

    setMarkers(data: MarkerInfo[]) {
        this.Markers = data;
    }

    setCurrentMarker(data: Marker | null) {
        this.currentMarker = data;
    }

    setMapCenter(center: MonitoringCoordinates) {
        this.mapCenter = center;
    }
}

export const $monitoringStore = new MonitoringStore();
