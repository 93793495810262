import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";
import HighlightedZoneDetails from "../../components/Zone/HighlightedZoneDetails";
import ZoneTable from "../../components/Zone/ZoneTable";
import { useCallback, useEffect } from "react";
import { $zoneTableDataStore } from "../../store/ZoneTableStore";
import { getZoneList } from "./ZoneListApi";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SearchField } from "@synapse/frontend-react";
import { StyledCard } from "../../App";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoute, DEFAULT_POINT } from "../../utils/const";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MainContentWrap from "../../ui/Wrappers/MainContentWrap";
import { $loginUserStore } from "../../store/ResponseData";
import { $houseListStore } from "../../store/HouseListData";

const ZoneList = observer(() => {
    const {
        page,
        rowsPerPage,
        sort,
        zoneSearch,
    } = $zoneTableDataStore;

    useEffect(() => {
        $zoneTableDataStore.setLoadingZoneTable(true);
        const getZoneListInfo = async () => {
            const data = await getZoneList(
                String(page + 1),
                sort.sort.by,
                sort.sort.order,
                String(rowsPerPage),
                zoneSearch
            );
            return data;
        };
        getZoneListInfo()
            .then((res) => {
                if (!res) return;

                $zoneTableDataStore.setTableLineData(res);
            })
            .finally(() => $zoneTableDataStore.setLoadingZoneTable(false));
    }, [page, rowsPerPage, sort.sort.by, sort.sort.order, zoneSearch]);

    let navigate = useNavigate();
    const handleAddNewZoneClick = useCallback(() => {
        $zoneTableDataStore.setSelectedZoneData(DEFAULT_POINT);
        $zoneTableDataStore.setNewCoordinates(undefined);
        $zoneTableDataStore.setCoordinates(undefined);
        $zoneTableDataStore.setReturnInitCoordinates(false);
        $houseListStore.setSearch('');
        navigate(
            generatePath(AppRoute.ZoneNew)
        );
    }, [navigate]);

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Зонирование</h3>
                            </Grid>
                            {
                                $loginUserStore.isAdmin()
                                ? <Grid item>
                                    <IconButton onClick={handleAddNewZoneClick}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                                : null
                            }
                        </Grid>
                    </Grid>
                    <Grid item alignSelf={"flex-end"}>
                        <Grid container direction={"row"}>
                            <SearchField
                                value={zoneSearch}
                                callback={(value) => $zoneTableDataStore.setSearch(value)}
                                callbackTimeout={700}
                                style={{
                                    width: 300,
                                    marginRight: 10
                                }}
                            />
                            <Grid item>
                                <IconButton
                                    onClick={() =>
                                        $zoneTableDataStore.setShowZoneDetailes(
                                            !$zoneTableDataStore.showZoneDetailes
                                        )
                                    }
                                    style={{ height: 46 }}
                                >
                                    {$zoneTableDataStore.showZoneDetailes ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={3}>
                        <Grid
                            item
                            xs={$zoneTableDataStore.showZoneDetailes ? 8 : 12}
                            lg={$zoneTableDataStore.showZoneDetailes ? 8 : 12}
                            sm={7}
                            md={8}
                        >
                            <ZoneTable />
                        </Grid>
                        {$zoneTableDataStore.showZoneDetailes && (
                            <Grid item xs={4} lg={4} sm={5} md={4}>
                                <HighlightedZoneDetails />
                            </Grid>
                        )}
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default ZoneList;
