import { Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { $contractsDataStore } from "../../../store/ContractsStore";
import { useCallback, useState, useEffect } from "react";
import {
    getContractCompletedWork,
    editContractCompletedWork
} from "../../../Pages/Contracts/ContractsApi";
import { CompletedWork } from "../../../types/completedWorks";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const StyledDatePicker = styled(DatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: "208px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto"
});

const StyledTextField = styled(TextField)({
    margin: "0 0 10px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const EditCompletedWork = observer(() => {
    const {
        currentCompletedWorkGuid,
        completedWorkPage,
        completedWorkRowsPerPage,
        completedWorkSort,
        completedWorkSearch,
        contractDetail,
        completedWorkList
    } = $contractsDataStore;

    const [form, setForm] = useState<CompletedWork>({
        guid: currentCompletedWorkGuid,
        contract: contractDetail?.guid!,
        start: "",
        end: "",
        planned_cost: ""
    });

    const [errors, setErrors] = useState({
        start: "",
        end: ""
    });

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    useEffect(() => {
        // Проверка на ошибки
        let startTextError = "";
        let endTextError = "";

        // Проверка: если даты отсутствуют
        if (!form.start) {
            startTextError = "Поле обязательное для заполнения";
        }
        if (!form.end) {
            endTextError = "Поле обязательное для заполнения";
        }

        // Проверка: если start больше end
        if (form.start && form.end) {
            const formStart = new Date(form.start).getTime();
            const formEnd = new Date(form.end).getTime();

            if (formStart > formEnd) {
                startTextError = "Дата начала не может быть позже даты окончания";
                endTextError = "Дата окончания не может быть раньше даты начала";
            }

            // Проверка на пересечения с другими периодами
            if (completedWorkList) {
                completedWorkList.items.forEach((item) => {
                    // исключаем сравнение элемента с самим собой в списке
                    if (item.guid !== form.guid) {
                        const itemStart = new Date(item.start).getTime();
                        const itemEnd = new Date(item.end).getTime();

                        // Проверяем, входит ли start или end в диапазон другого периода
                        if (formStart >= itemStart && formStart <= itemEnd) {
                            startTextError = "Дата начала пересекается с другими периодами";
                        }

                        if (formEnd >= itemStart && formEnd <= itemEnd) {
                            endTextError = "Дата окончания пересекается с другими периодами";
                        }

                        // проверяем, пересекает ли введенный период полностью другой период
                        if (itemStart >= formStart && itemEnd <= formEnd) {
                            endTextError = "Введенный период пересекается с другим периодом";
                        }
                    }
                });
            }
        }

        const fieldsErrors = { start: startTextError, end: endTextError };
        setErrors(fieldsErrors);
        
    }, [form.start, form.end]);

    const handleSubmit = async () => {
        $contractsDataStore.setLoading(true);
        await editContractCompletedWork(form).then((contract) => {
            $contractsDataStore.getCompletedWorkList(
                String(contractDetail?.guid),
                String(completedWorkPage + 1),
                completedWorkSort.sort.by,
                completedWorkSort.sort.order,
                String(completedWorkRowsPerPage),
                completedWorkSearch
            );
            $contractsDataStore.getDetail(String(contractDetail?.guid));
            $contractsDataStore.setEditCompletedWork(false);
        });
        $contractsDataStore.setCompletedWorkLoading(false);
        clearForm();
    };

    const getCompletedWorkData = async () => {
        if (form.guid) {
            await getContractCompletedWork(form.guid).then((work) => {
                if (work) {
                    setForm({
                        ...form,
                        start: work.start,
                        end: work.end,
                        planned_cost: work.planned_cost
                    });
                }
            });
        }
    };

    useEffect(() => {
        getCompletedWorkData();
    }, [form.guid, $contractsDataStore.editCompletedWork]);

    const clearForm = () => {
        setForm({
            ...form,
            guid: "",
            contract: "",
            start: "",
            end: "",
            planned_cost: ""
        });
        $contractsDataStore.setCurrentCompletedWorkGuid("");
    };

    const formatCurrencyInput = (value: string) => {
        // Удаляем все символы, кроме цифр и точки
        let sanitizedValue = value.replace(/[^0-9.]/g, "");

        // Разрешаем только одну точку
        const parts = sanitizedValue.split(".");
        if (parts.length > 2) {
            sanitizedValue = `${parts[0]}.${parts[1]}`;
        }

        // Ограничиваем количество знаков после точки до двух
        if (parts[1] && parts[1].length > 2) {
            sanitizedValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
        }

        return sanitizedValue;
    };

    return (
        <StyledDialog
            open={$contractsDataStore.editCompletedWork}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset"
                }
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white"
                }}
                onClick={() => {
                    $contractsDataStore.setEditCompletedWork(false);
                    clearForm();
                }}
            >
                <CloseIcon fontSize="large" />
            </IconButton>
            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff"
                                    }}
                                    component={"span"}
                                >
                                    {(form.guid ? "Изменить " : "Добавить ") +
                                        "акт выполненных работ"}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%"
                            }}
                        >
                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePicker
                                            value={dayjs(form.start)}
                                            label="Начало периода"
                                            format="DD.MM.YYYY"
                                            name="start"
                                            slotProps={{
                                                field: {
                                                    clearable: true
                                                },
                                                textField: {
                                                    error: !!errors.start,
                                                    helperText: errors.start
                                                }
                                            }}
                                            timezone="system"
                                            onChange={(value) =>
                                                setFormValue(
                                                    "start",
                                                    value ? dayjs(value).format("YYYY-MM-DD") : ""
                                                )
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePicker
                                            value={dayjs(form.end)}
                                            label="Окончание периода"
                                            format="DD.MM.YYYY"
                                            name="end"
                                            slotProps={{
                                                field: {
                                                    clearable: true
                                                },
                                                textField: {
                                                    error: !!errors.end,
                                                    helperText: errors.end
                                                }
                                            }}
                                            timezone="system"
                                            onChange={(value) =>
                                                setFormValue(
                                                    "end",
                                                    value ? dayjs(value).format("YYYY-MM-DD") : ""
                                                )
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={12}>
                                        <StyledTextField
                                            label="Стоимость план. (договор)"
                                            fullWidth
                                            size="medium"
                                            variant="outlined"
                                            value={form.planned_cost}
                                            onChange={(event) => {
                                                const formattedValue = formatCurrencyInput(
                                                    event.target.value
                                                );
                                                setFormValue("planned_cost", formattedValue);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <ButtonSquare
                                disabled={!!errors.start || !!errors.end}
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto"
                                }}
                                onClick={handleSubmit}
                            >
                                {form.guid ? "Изменить" : "Добавить"}
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default EditCompletedWork;
