import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, TextField } from "@mui/material";
import CustomMap from "./Map";
import { observer } from "mobx-react";
import "leaflet/dist/leaflet.css";
import { styled } from "@mui/material/styles";
import { getUKName } from "../../utils/utils";
import { $zoneTableDataStore } from "../../store/ZoneTableStore";
import { useParams } from "react-router-dom";
import { ButtonSquare } from "@synapse/frontend-react";
import { saveZone } from "../../Pages/Zone/ZoneListApi";
import Notification from "../../components/Notification";
import { toast } from "react-toastify";
import { $loginUserStore } from "../../store/ResponseData";

const StyledCard = styled(Card)({
    height: "98%",
    border: "1px solid #5ac8fa",
    boxShadow: "0 0 10px 0px rgb(0 0 0 / 9%)",
    borderRadius: 15,
    padding: 15,
    maxWidth: "100%",
    maxHeight: "100%"
});

const StyledTextField = styled(TextField)({
    margin: "3px 0",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const HighlightedZoneDetails = observer(() => {
    const [showButtons, setShowButtons] = useState(false);
    const routeParams = useParams();
    const {
        selectedZoneData,
        zoneCoordinates,
    } = $zoneTableDataStore;
    const [description, setDescription] = useState(selectedZoneData?.description);

    // изменяем признак возврата к первоначальным сохраненным координатам
    $zoneTableDataStore.setReturnInitCoordinates(false);

    const returnCoords = async () => {
        $zoneTableDataStore.setReturnInitCoordinates(true);
    };

    const handleSubmit = async () => {
        if ($loginUserStore.isAdmin()) {
            $zoneTableDataStore.setLoadingZoneTable(true);

            const form = {
                id: selectedZoneData.id,
                coordinates: JSON.stringify(zoneCoordinates),
                description: description,
                house: selectedZoneData.house,
                uk: selectedZoneData.uk,
                guid: selectedZoneData.guid,
                name: selectedZoneData.name
            };

            await saveZone(form).then((zone) => {
                if (zone) {
                    $zoneTableDataStore.setSelectedZoneData(zone);
                    Notification({
                        title: "Зона успешно сохранена",
                        type: toast.TYPE.SUCCESS
                    });
                } else {
                    Notification({
                        title: "Не удалось сохранить зону",
                        type: toast.TYPE.ERROR
                    });
                }
            });
            $zoneTableDataStore.setLoadingZoneTable(false);
        }
    };

    // Сравниваем координаты первоначально сохраненной фигуры и координаты текущей фигурой
    useEffect(() => {
        if (selectedZoneData && zoneCoordinates) {
            const selectedCoordJSON = JSON.stringify(selectedZoneData.coordinates.coordinates[0]);
            const selectedCoord = JSON.parse(selectedCoordJSON);
            const newCoordJSON = JSON.stringify(zoneCoordinates);
            const newCoord = JSON.parse(newCoordJSON).geometry.coordinates[0];

            const arrayIsEqual = (arr1: [], arr2: []) => {
                if (arr1.length !== arr2.length) {
                    return false;
                }
                for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i][0] !== arr2[i][0] || arr1[i][1] !== arr2[i][1]) {
                        return false;
                    }
                }
                return true;
            };

            // показываем/скрываем кнопки сохранить/отменить
            if (!arrayIsEqual(selectedCoord, newCoord)) {
                setShowButtons(true);
            } else {
                setShowButtons(false);
            }
        }
    }, [selectedZoneData, zoneCoordinates]);

    useEffect(() => {
        if (selectedZoneData && zoneCoordinates) {
            if (selectedZoneData.description !== description) {
                setShowButtons(true);
            }
        }
    }, [description]);

    useEffect(() => {
        setDescription(selectedZoneData.description);
    }, [selectedZoneData]);

    // Отдельной отслеживаем координаты текущей фигуры.
    // Если они были и пропали (удаление фигуры) - показываем кнопкисохранить/отменить
    useEffect(() => {
        if (!zoneCoordinates) {
            setShowButtons(true);
        }
    }, [zoneCoordinates]);

    return (
        <StyledCard style={{ height: "auto" }}>
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                    <Grid container spacing={1} direction={"column"}>
                        {!routeParams.guid && (
                            <Grid item>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-evenly"}
                                >
                                    <Grid item>
                                        <h3>Детали зоны</h3>
                                    </Grid>
                                    <Grid item style={{ fontWeight: "bold" }}>
                                        {selectedZoneData?.id}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <StyledTextField
                                label="Дом"
                                InputProps={{
                                    readOnly: true
                                }}
                                size="medium"
                                variant="outlined"
                                fullWidth
                                value={selectedZoneData?.name}
                            />
                        </Grid>
                        <Grid item>
                            <StyledTextField
                                label="Управляющая компания"
                                InputProps={{
                                    readOnly: true
                                }}
                                size="medium"
                                variant="outlined"
                                fullWidth
                                value={selectedZoneData?.uk ? getUKName(selectedZoneData?.uk) : " "}
                            />
                        </Grid>
                        <Grid item>
                            <StyledTextField
                                label="Описание"
                                InputProps={{
                                    readOnly: !routeParams.guid || !$loginUserStore.isAdmin()
                                }}
                                size="medium"
                                variant="outlined"
                                fullWidth
                                value={description}
                                onChange={(value) =>
                                    setDescription(value.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {!routeParams.guid && (
                    <Grid item>
                        <CustomMap
                            style={{
                                height: 450
                            }}
                        />
                    </Grid>
                )}
                {showButtons && $loginUserStore.isAdmin() && (
                    <Grid item container direction={"row"} justifyContent={"space-between"}>
                        <Grid item>
                            <ButtonSquare variant="contained" onClick={handleSubmit}>
                                Обновить
                            </ButtonSquare>
                        </Grid>
                        <Grid item>
                            <ButtonSquare variant="outlined" onClick={returnCoords}>
                                Отменить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </StyledCard>
    );
});

export default HighlightedZoneDetails;
