import axios from "../../axios";
import { UserData } from "../../types/user.data";
import { toast } from "react-toastify";
import { Authorization } from "../../types/authorization";
import Notification from "../../components/Notification";

export async function loginUser(form: UserData) {
    let data = { login: form.login, password: form.password };
    let config = {
        method: "post",
        url: "/UserService/Auth/login",
        data: data,
    };
    try {
        const response = await axios.request(config);
        const result = response.data;
        if (!result) {
            Notification({
                title: 'Не удалось получить ответ',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        if (result?.error?.status_code === 401) {
            Notification({
                title: 'Не удалось авторизоваться. Проверьте логин и пароль',
                type: toast.TYPE.ERROR,
            });
            return;
        }
        localStorage.setItem('userData', JSON.stringify(result));
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось авторизоваться. Проверьте логин и пароль',
            type: toast.TYPE.ERROR,
        });
    }
}

export async function checkToken() {
    let config = {
        method: "get",
        url: "/UserService/Auth/check",
    };
    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Authorization = response.data;
        if (!result) return;
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось загрузить данные',
            type: toast.TYPE.ERROR,
        });
    }
}
