import {
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import {
    AddButton,
    SearchField,
} from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { addContractHouse } from '../../Pages/Contracts/ContractsApi';
import { $anchorRefStore } from '../../store/AnchorRef';
import { $contractsDataStore } from '../../store/ContractsStore';
import { $loginUserStore } from '../../store/ResponseData';
import { contractAddress } from '../../types/contractAddress';
import { DEFAULT_CONTRACT_ADDRESS } from '../../utils/const';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import AddHouse from './AddHouse';
import ContractCardAddressTableLine from './ContractCardAddressTableLine';

const ContractCardAddress = observer(() => {
    const {
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort,
        contractAddressSearch,
        houseList,
        contractDetail,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getHouseList(
            String(contractDetail?.guid),
            String(contractAddressPage + 1),
            contractAddressSort.sort.by,
            contractAddressSort.sort.order,
            String(contractAddressRowsPerPage),
            contractAddressSearch
        );
    }, [
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort.sort.by,
        contractAddressSort.sort.order,
        contractDetail?.guid,
        contractAddressSearch,
    ]);

    const tableColumns = [
        {
            key: "id",
            label: "№ п/п",
            width: 100,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "address",
            label: "Адрес обслуживания",
            width: 300,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "zone",
            label: "Зона",
            width: 150,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "start",
            label: "Начало обслуживания",
            width: 200,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "end",
            label: "Окончание обслуживания",
            width: 200,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "status",
            label: "Статус",
            width: 150,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "action",
            label: "Действия",
            width: 100,
            hideSortIcon: true,
            show: $loginUserStore.isAdmin(),
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            $contractsDataStore.setAddressSort({
                sort: {
                    by: key,
                    order:
                        contractAddressSort.sort.by !== key
                            ? 'asc'
                            : contractAddressSort.sort.order === 'asc' ? 'desc' : 'asc',
                },
            });
        },
        [contractAddressSort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $contractsDataStore.setAddressPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $contractsDataStore.setAddressRowsPerPage(
            parseInt(event.target.value, 10)
        );
        $contractsDataStore.setAddressPage(0);
    };

    const handleAddAddress = () => {
        $contractsDataStore.setAddNewHouse(true);
    };

    const handleChangeStatus = async (form: contractAddress, status: string) => {
        setLoading(true);
        (form as any).status = status;
        $contractsDataStore.setLoading(true);
        await addContractHouse(
            form
        ).then((contract) => {
            $contractsDataStore.setAddressEdit(false);
            $contractsDataStore.getHouseList(
                String(contractDetail?.guid),
                String(contractAddressPage + 1),
                contractAddressSort.sort.by,
                contractAddressSort.sort.order,
                String(contractAddressRowsPerPage),
                contractAddressSearch
            );
            $contractsDataStore.setAddNewHouse(false);
        });
        $contractsDataStore.setAddressLoading(false);
        setLoading(false);
    };

    const [loading, setLoading] = useState<boolean>(false);

    return (<>
        <Grid container justifyContent={$loginUserStore.isAdmin() ? 'space-between' : 'flex-end'}>
            {
                $loginUserStore.isAdmin()
                    ? <AddButton
                        onClick={handleAddAddress}
                        disabled={contractDetail?.available_houses?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                    : null
            }
            <SearchField
                value={contractAddressSearch}
                callback={(value) => $contractsDataStore.setAddressSearch(value)}
                callbackTimeout={700}
                style={{
                    width: 300
                }}
            />
        </Grid>
        <TableContainer
            component={Paper}
            style={{
                border: "1px solid #5ac8fa",
                margin: '15px 0 0',
                // maxHeight: 875,
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((item: any) => {
                            if (!item.show) {
                                return <></>;
                            }
                            return (
                                <TableCell
                                    key={item.key}
                                    id={item.key}
                                    sortDirection={
                                        contractAddressSort.sort.by === item.key
                                            ? contractAddressSort.sort.order
                                            : false
                                    }
                                    style={{
                                        width: item.width,
                                        maxWidth: item.width,
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            contractAddressSort.sort.by === item.key
                                        }
                                        hideSortIcon={item.hideSortIcon}
                                        direction={
                                            contractAddressSort.sort.by === item.key
                                                ? contractAddressSort.sort.order
                                                : "asc"
                                        }
                                        onClick={() => {
                                            if (!item.hideSortIcon) {
                                                changeTableSort(item.key);
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ContractCardAddressTableLine />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={houseList?.pagination.total! ?? 0}
                page={contractAddressPage}
                onPageChange={handleChangePage}
                rowsPerPage={contractAddressRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} из ${count}`
                }
                labelRowsPerPage="Cтрок на странице:"
                style={{ display: "flex" }}
            />
        </TableContainer>
        <Menu
            disableScrollLock={true}
            anchorEl={$anchorRefStore.data}
            // keepMounted
            open={Boolean($anchorRefStore.data)}
            onClose={() => {
                $anchorRefStore.setAnchorRef(null);
                $contractsDataStore.setAddressEditData(DEFAULT_CONTRACT_ADDRESS);
            }}
        >
            <MenuItem
                onClick={(event) => {
                    $anchorRefStore.setAnchorRef(null);
                    $contractsDataStore.setAddressEdit(true);
                }}
            >{
                $contractsDataStore?.addressEditData.status === 'WORK'
                    ? 'Архивировать'
                    : 'Вернуть из архива'
            }</MenuItem>
        </Menu>
        <ConfirmDialog
            display={$contractsDataStore.addressEdit}
            title={
                $contractsDataStore?.addressEditData.status === 'WORK'
                    ? 'Архивировать адрес'
                    : 'Вернуть из архива'
            }
            submitText={
                $contractsDataStore?.addressEditData.status === 'WORK'
                    ? 'Архивировать'
                    : 'Вернуть'
            }
            onClose={(event) => $contractsDataStore.setAddressEdit(false)}
            onSubmit={(event) => {
                handleChangeStatus(
                    $contractsDataStore?.addressEditData,
                    $contractsDataStore?.addressEditData.status === 'WORK'
                        ? 'EXCLUDED'
                        : 'WORK'
                );
            }}
            loading={loading}
        >
            <Typography variant="subtitle1">
                Вы уверены, что хотите{" "}
                {$contractsDataStore?.addressEditData.status === 'WORK'
                    ? 'отправить в архив'
                    : 'вернуть из архива'}
                {" "}адрес{" "}
                <b>{$contractsDataStore?.addressEditData?.name}</b>{" "}
                для текущего договора?
            </Typography>
        </ConfirmDialog>
        {$contractsDataStore.addNewHouse && <AddHouse />}
    </>);
});
export default ContractCardAddress;
