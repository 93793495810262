import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./Pages/Login/Login";
import { BrowserRouter, generatePath, Navigate, Route, Routes, } from "react-router-dom";
import { AppRoute } from "./utils/const";
import NotFound from "./Pages/NotFound";
import { CustomCircularProgress } from "./components/CustomCircularProgress";
import { styled } from "@mui/material/styles";
import { Card, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { $loginUserStore } from "./store/ResponseData";
import MainMenu from "./components/MainMenu";
import { $mainMenuStore } from "./store/MenuStore";
import ZoneList from "./Pages/Zone/ZoneList";
import { $ukStore } from "./store/UkStore";
import { $organisationsStore } from "./store/OrganisationsStore";
import Contracts from "./Pages/Contracts/Contracts";
import Members from "./Pages/Members/Members";
import DevicesList from "./Pages/Devices/DevicesList";
import ContractDetail from "./Pages/Contracts/ContractDetail";
import ZoneDetail from "./Pages/Zone/ZoneDetail";
import AddNewZone from "./Pages/Zone/AddNewZone";
import Monitoringv3 from "./Pages/Monitoring/Monitoringv3";

export const StyledCard = styled(Card)({
    height: '100%',
    border: '1px solid #5ac8fa',
    boxShadow: '0 0 10px 0px rgb(0 0 0 / 9%)',
    borderRadius: 15,
    padding: 15,
    marginBottom: 15,

    '& .MuiTableCell-head': {
        background: '#f6fbff',
        lineHeight: '1.1rem',
        borderBottom: '1px solid rgba(32, 160, 255, 0.5)',
        fontWeight: 800,
        whiteSpace: 'nowrap',
        padding: 10,
    },

    '& .MuiTableCell-body': {
        padding: 10,
    },
});

const App = observer((): JSX.Element => {
    const [loading, setLoading] = useState(true);

    const {
        token,
        data: authData
    } = $loginUserStore;

    useEffect(() => {
        setLoading(true);
        const serverLoginResponseString = localStorage.getItem('userData') ?? undefined;
        if (serverLoginResponseString === undefined) {
            setLoading(false);
            return;
        }

        const serverLoginResponse = JSON.parse(serverLoginResponseString);
        $loginUserStore.setData(serverLoginResponse.data);

        $organisationsStore.getData();
        $ukStore.getData();

        setLoading(false);
    }, [token]);

    if (
        authData &&
        Object.keys(authData).length === 0
    ) {
        setLoading(false);
        return <></>;
    }

    if (authData === undefined && !loading) {
        return <Login />;
    }

    if (loading) {
        return <CustomCircularProgress />;
    }

    return (
        <BrowserRouter>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
            >
                <Grid
                    item
                    xs={$mainMenuStore.menuStatus ? 3 : 1}
                    lg={$mainMenuStore.menuStatus ? 2 : 1}
                >
                    <MainMenu />
                </Grid>
                <Grid
                    item
                    xs={$mainMenuStore.menuStatus ? 9 : 11}
                    lg={$mainMenuStore.menuStatus ? 10 : 11}
                >
                    <Grid
                        container
                        direction="column"
                    >
                        <Grid item style={{
                            height: '100%',
                            width: '100%',
                            minHeight: '100vh',
                        }}>
                            <Routes>
                                <Route path={AppRoute.Root}>
                                    <Route
                                        index
                                        element={
                                            <Navigate
                                                to={generatePath(AppRoute.Monitoring)}
                                            />
                                        }
                                    />
                                    <Route
                                        path={AppRoute.Login}
                                        element={<Login />}
                                    />
                                    <Route
                                        path={AppRoute.ZoneList}
                                        element={<ZoneList />}
                                    />
                                    <Route
                                        path={AppRoute.ZoneNew}
                                        element={<AddNewZone />}
                                    />
                                    <Route
                                        path={AppRoute.ZoneDetail}
                                        element={<ZoneDetail />}
                                    />
                                    <Route
                                        path={AppRoute.Monitoring}
                                        element={<Monitoringv3 />}
                                    />
                                    <Route
                                        path={AppRoute.Monitoringv2}
                                        element={
                                            <Navigate
                                                to={generatePath(AppRoute.Monitoring)}
                                            />
                                        }
                                    />
                                    <Route
                                        path={AppRoute.Monitoringv3}
                                        element={
                                            <Navigate
                                                to={generatePath(AppRoute.Monitoring)}
                                            />
                                        }
                                    />
                                    <Route
                                        path={AppRoute.ContractList}
                                        element={<Contracts />}
                                    />
                                    <Route
                                        path={AppRoute.ContractDetail}
                                        element={<ContractDetail />}
                                    />
                                    <Route
                                        path={AppRoute.Members}
                                        element={<Members />}
                                    />
                                    <Route
                                        path={AppRoute.Devices}
                                        element={<DevicesList />}
                                    />
                                </Route>
                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                            </Routes>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BrowserRouter>
    );
});

export default App;
