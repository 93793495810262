import React from "react";
import { observer } from "mobx-react";
import { EditButton } from "@synapse/frontend-react";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { $deviceTableDataStore } from "../../store/DeviceTableStore";
import Tooltip from "@mui/material/Tooltip";

const ColumnSelectBtn = observer(() => {
    const { tableColumns } = $deviceTableDataStore;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (key: string) => {
        const updatedTableColumns = tableColumns.map((el) => {
            if (el.key === key) {
                el.show = !el.show;
            }

            return el;
        });

        $deviceTableDataStore.setTableColumns(updatedTableColumns);
    };

    return (
        <>
            <Tooltip arrow title={"Настроить отображения колонок"}>
                <span style={{ height: 40, display: "block" }}>
                    <EditButton
                        style={{
                            marginLeft: "0"
                        }}
                        variant={"text"}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        {""}
                    </EditButton>
                </span>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {tableColumns.map((column) => {
                        const labelId = `checkbox-list-label-${column.key}`;

                        return (
                            <ListItem key={column.key} disablePadding>
                                <ListItemButton
                                    role={undefined}
                                    onClick={() => handleToggle(column.key)}
                                    dense
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={column.show}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={column.label} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Menu>
        </>
    );
});

export default ColumnSelectBtn;
