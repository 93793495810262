import { createControlComponent } from "@react-leaflet/core";
import * as L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { $loginUserStore } from "../../store/ResponseData";

interface Props extends L.ControlOptions {
    position: L.ControlPosition;
    oneBlock?: boolean;
    drawPolyline: boolean;
    drawCircle: boolean;
    drawCircleMarker: boolean;
    drawRectangle: boolean;
    drawPolygon: boolean;
    drawText: boolean;
    dragMode: boolean;
    rotateMode: boolean;
    drawMarker: boolean;
    cutPolygon: boolean;
    removalMode: boolean;
    editMode: boolean;
}

const Geoman = L.Control.extend({
    options: {},
    initialize(options: Props) {
        L.setOptions(this, options);
    },

    addTo(map: L.Map) {
        if (!map.pm) {
            return;
        }

        if (!$loginUserStore.isAdmin()) {
            return;
        }

        map.pm.addControls({
            ...this.options,
            drawRectangle: true,
            drawPolygon: true
        });

        map.pm.setLang("ru");
    }
});

const createGeomanInstance = (props: Props) => {
    return new Geoman(props);
};

export const GeomanControl = createControlComponent(createGeomanInstance);
