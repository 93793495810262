import { Autocomplete, Dialog, DialogTitle, Grid, IconButton, TextField, Typography, } from "@mui/material";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState, } from "react";
import { $ukStore } from "../../store/UkStore";
import { $membersDataStore } from "../../store/MembersStore";
import { AddMemberToDevice } from "../../types/addMemberToDevice";
import { $deviceTableDataStore } from "../../store/DeviceTableStore";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ButtonSquare } from "@synapse/frontend-react";
import { attachDevice, detachDevice } from "../../Pages/Devices/DevicesApi";
import Notification from "../Notification";
import { toast } from "react-toastify";
import { $organisationsStore } from "../../store/OrganisationsStore";
import 'dayjs/locale/ru';
import dayjs from "dayjs";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const StyledTextField = styled(TextField)({
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap",
});

const StyledDateTimePicker = styled(DateTimePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: 300,
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const Attach = observer(() => {
    const isDetach = $membersDataStore.detach;

    const search = isDetach
        ? $deviceTableDataStore?.editDeviceData.current_user!
        : '';

    const {
        membersData,
        membersloadingTable,
    } = $membersDataStore;

    const minDateTime = dayjs($deviceTableDataStore.editDeviceData?.min_attach_date);

    const [form, setForm] = useState<AddMemberToDevice>({
        device: $deviceTableDataStore?.editDeviceData.guid!,
        date: "",
        user: "",
        user_name: "",
        organisation: "",
        organisation_name: "",
    });

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isDetach && membersData?.items.length === 1) {
            setForm({
                ...form,
                user: membersData?.items[0].guid!,
                user_name: membersData?.items[0].name!,
                organisation: membersData?.items[0].organisation.guid!,
                organisation_name: membersData?.items[0].organisation.name!,
            });
        }
    }, [form, membersData?.items, isDetach]);

    useEffect(() => {
        if (form.organisation !== '' || isDetach) {
            $membersDataStore.getData(
                '1',
                'name',
                'asc',
                '99999',
                search,
                form.organisation
            );
        }
    }, [
        form.organisation,
        isDetach,
        search,
    ]);

    const { data: uks } = $ukStore;
    const { data: organisations } = $organisationsStore;

    const fullOrgList = useMemo(() => {
        const ukList = uks?.map((item) => {
            return {
                label: item.name,
                id: item.guid,
            }
        });
        const organisationList = organisations?.map((item) => {
            return {
                label: item.name,
                id: item.guid,
            }
        });

        if (ukList !== undefined && organisationList !== undefined) {
            return ukList.concat(organisationList);
        }

        return [];
    }, [uks, organisations]);

    const members = useMemo(() => {
        if (membersData?.items.length !== undefined) {
            return membersData?.items.map((item) => {
                return {
                    label: item.name,
                    id: item.guid,
                }
            });
        }
    }, [membersData?.items]);

    const setFormValue = useCallback(
        (name: string, value: string | EventTarget) => {
            setForm({ ...form, [name]: value });

            if (value !== '' && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: ''
                })
            }
        },
        [form, errors]
    );

    const handleOrganisationChange = useCallback(
        (evt: SyntheticEvent<Element, Event>, value: object | unknown) => {
            if (value === null) {
                value = {
                    id: '',
                    label: '',
                };
            }
            setForm({
                ...form,
                organisation: (value as any)?.id,
                organisation_name: (value as any)?.label,
            });
        },
        [form]
    );

    const handleUserChange = useCallback(
        (evt: SyntheticEvent<Element, Event>, value: object | unknown) => {
            setForm({
                ...form,
                user: (value as any)?.id,
                user_name: (value as any)?.label,
            });
        },
        [form]
    );

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.user === '') {
            newErrors.user = 'Поле "Сотрудник" обязательно для заполнения';
        }
        if (form.date === '') {
            newErrors.date = 'Поле "' + (isDetach ? 'Дата завершения' : 'Дата начала') + '" обязательно для заполнения';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $deviceTableDataStore.setLoadingDeviceTable(true);
            if (isDetach) {
                await detachDevice(form).then((response) => {
                    $deviceTableDataStore.updateData();
                    $membersDataStore.setDetach(false);

                    if (response.message) {
                        Notification({
                            title: response.message,
                            type: toast.TYPE.ERROR,
                        });
                    } else {
                    Notification({
                        title: "Сотрудник откреплён",
                        type: toast.TYPE.SUCCESS,
                        duration: 30000
                    });
                }});
            } else {
                await attachDevice(form).then((response) => {
                    $deviceTableDataStore.updateData();
                    $membersDataStore.setAttach(false);

                    if (response.message) {
                        Notification({
                            title: response.message,
                            type: toast.TYPE.ERROR,
                        });
                    } else {
                        Notification({
                            title: "Сотрудник закреплён",
                            type: toast.TYPE.SUCCESS,
                            duration: 30000
                        });
                    }
                });
            }
            $deviceTableDataStore.setLoadingDeviceTable(false);
        }
    };

    return (
        <StyledDialog
            open={
                $membersDataStore.attach ||
                $membersDataStore.detach
            }
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => {
                    $membersDataStore.setAttach(false);
                    $membersDataStore.setDetach(false);
                }}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        spacing={2}
                    >
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                    }}
                                    component={"span"}
                                >
                                    {
                                        isDetach
                                            ? 'Открепить сотрудника'
                                            : 'Закрепить сотрудника'
                                    }
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={12}>
                                    <StyledTextField
                                        placeholder="Идентификатор устройства"
                                        label="Идентификатор устройства"
                                        required
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        size="medium"
                                        variant="outlined"
                                        id="device"
                                        name="device"
                                        fullWidth
                                        value={
                                            $deviceTableDataStore.editDeviceData
                                                ?.imei
                                        }
                                        error={errors.device?.length > 0}
                                        helperText={errors.device?.length > 0 ? errors.device : false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={12}>
                                    <StyledTextField
                                        placeholder="Название устройства"
                                        label="Название устройства"
                                        required
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        size="medium"
                                        variant="outlined"
                                        id="device_name"
                                        name="device_name"
                                        fullWidth
                                        value={
                                            $deviceTableDataStore.editDeviceData
                                                ?.name
                                        }
                                        error={errors.device?.length > 0}
                                        helperText={errors.device?.length > 0 ? errors.device : false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {" "}
                            <StyledAutocomplete
                                noOptionsText={"Организации не найдены"}
                                value={form.organisation_name}
                                id="organisation"
                                options={fullOrgList}
                                disabled={isDetach}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        placeholder="Организация"
                                        label="Организация"
                                        error={errors.organisation?.length > 0}
                                        helperText={errors.organisation?.length > 0 ? errors.organisation : false}
                                    />
                                )}
                                onChange={handleOrganisationChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledAutocomplete
                                noOptionsText={"Сотрудники не найдены"}
                                id="user"
                                options={
                                    members !== undefined && (form.organisation !== "" || isDetach)
                                        ? members
                                        : []
                                }
                                disabled={
                                    form.organisation === '' ||
                                    form.organisation === undefined ||
                                    isDetach ||
                                    membersloadingTable
                                }
                                value={form.user_name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        placeholder="Сотрудник"
                                        label="Сотрудник"
                                        error={errors.user?.length > 0}
                                        helperText={errors.user?.length > 0 ? errors.user : false}
                                    />
                                )}
                                onChange={handleUserChange}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                <StyledDateTimePicker
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    label={isDetach ? 'Дата завершения' : 'Дата начала'}
                                    format="DD.MM.YYYY HH:mm:ss"
                                    name="date"
                                    slotProps={{
                                        field: {
                                            clearable: true,
                                        },
                                        textField: {
                                            error: errors.date?.length > 0,
                                            helperText: errors.date?.length > 0 ? errors.date : false,
                                            inputProps: {
                                                readOnly: true
                                            },
                                            onClick:() => setOpen(true)
                                        },
                                    }}
                                    minDateTime={minDateTime}
                                    onChange={(value) =>
                                        value &&
                                        setFormValue(
                                            "date",
                                            value.format("DD.MM.YYYY HH:mm:ss")
                                        )
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <ButtonSquare
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default Attach;
