import {
    Autocomplete,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { $contractsDataStore } from "../../store/ContractsStore";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { $ukStore } from "../../store/UkStore";
import { $organisationsStore } from "../../store/OrganisationsStore";
import CustomTooltip from "../Tooltip";
import { newContractCreate } from "../../Pages/Contracts/ContractsApi";

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap"
});

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto"
});

const StyledDatePicker = styled(DatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: "208px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const StyledTextField = styled(TextField)({
    margin: "19px 0 9px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const AddContract = observer(() => {
    const {
        contractsPage,
        contractsRowsPerPage,
        contractsSort,
        contractsSearch,
        editContractData
    } = $contractsDataStore;

    const [form, setForm] = useState(editContractData);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const { data: uks } = $ukStore;
    const ukList = useMemo(() => {
        return uks?.map((item) => item.name);
    }, [uks]);

    const { data: organisations } = $organisationsStore;
    const organisationList = useMemo(() => {
        return organisations?.map((item) => item.name);
    }, [organisations]);

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
            if (value !== "" && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: ""
                });
            }
        },
        [form, errors]
    );

    const handleChangeUk = useCallback(
        (evt: SyntheticEvent<Element, Event>, value: string | unknown) => {
            setForm({
                ...form,
                from: uks?.find((item) => item.name === value)?.guid ?? ""
            });
        },
        [uks, form]
    );

    const handleChangeOrganisation = useCallback(
        (evt: SyntheticEvent<Element, Event>, value: string | unknown) => {
            setForm({
                ...form,
                to: organisations?.find((item) => item.name === value)?.guid ?? ""
            });
        },
        [organisations, form]
    );

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.from === "") {
            newErrors.from = 'Поле "Управляющая компания" обязательно для заполнения';
        }
        if (form.to === "") {
            newErrors.to = 'Поле "Организация" обязательно для заполнения';
        }
        if (form.number === "") {
            newErrors.number = 'Поле "№ договора" обязательно для заполнения';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $contractsDataStore.setLoading(true);
            await newContractCreate(form).then((contract) => {
                if (contract) {
                    $contractsDataStore.getData(
                        String(contractsPage + 1),
                        contractsSort.sort.by,
                        contractsSort.sort.order,
                        String(contractsRowsPerPage),
                        contractsSearch
                    );
                    $contractsDataStore.setOpenEditContract(false);
                }
            });
            $contractsDataStore.setLoading(false);
        }
    };

    return (
        <StyledDialog
            open={$contractsDataStore.openEditContract}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset"
                }
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white"
                }}
                onClick={() => $contractsDataStore.setOpenEditContract(false)}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff"
                                    }}
                                    component={"span"}
                                >
                                    {form.guid
                                        ? "Редактирование договора"
                                        : "Создание нового договора"}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%"
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledAutocomplete
                                            noOptionsText={"УК не найдена"}
                                            id="from"
                                            value={
                                                uks?.find((item) => item.guid === form.from)?.name
                                            }
                                            options={ukList !== undefined ? ukList : [""]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Управляющая компания"
                                                    label="Управляющая компания"
                                                    required
                                                    error={errors.from?.length > 0}
                                                    helperText={
                                                        errors.from?.length > 0
                                                            ? errors.from
                                                            : false
                                                    }
                                                />
                                            )}
                                            onChange={handleChangeUk}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "2%" }}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledAutocomplete
                                            noOptionsText={"Организация не найдена"}
                                            id="to"
                                            value={
                                                organisations?.find((item) => item.guid === form.to)
                                                    ?.name
                                            }
                                            options={
                                                organisationList !== undefined
                                                    ? organisationList
                                                    : [""]
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Организация"
                                                    label="Организация"
                                                    required
                                                    error={errors.to?.length > 0}
                                                    helperText={
                                                        errors.to?.length > 0 ? errors.to : false
                                                    }
                                                />
                                            )}
                                            onChange={handleChangeOrganisation}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <Grid
                                            container
                                            direction={"row"}
                                            justifyContent={"space-between"}
                                        >
                                            <Grid item>
                                                {" "}
                                                <StyledTextField
                                                    placeholder="№ договора"
                                                    label="№ договора"
                                                    sx={{ width: "208px" }}
                                                    size="medium"
                                                    variant="outlined"
                                                    fullWidth
                                                    id="number"
                                                    name="number"
                                                    required
                                                    value={form.number}
                                                    onChange={(value) =>
                                                        setFormValue("number", value.target.value)
                                                    }
                                                    error={errors.number?.length > 0}
                                                    helperText={
                                                        errors.number?.length > 0
                                                            ? errors.number
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid item style={{ marginTop: 18 }}>
                                                {" "}
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <StyledDatePicker
                                                        value={dayjs(form.date)}
                                                        label="Дата договора"
                                                        format="DD.MM.YYYY"
                                                        name="date"
                                                        slotProps={{
                                                            field: {
                                                                clearable: true
                                                            }
                                                        }}
                                                        timezone="system"
                                                        onChange={(value) =>
                                                            setFormValue(
                                                                "date",
                                                                value
                                                                    ? dayjs(value).format("YYYY-MM-DD")
                                                                    : ""
                                                            )
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {" "}
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "2%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid container direction={"row"} spacing={2}>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={2}
                                                justifyContent={"space-between"}
                                            >
                                                <Grid item>
                                                    {" "}
                                                    <StyledDatePicker
                                                        value={dayjs(form.start)}
                                                        label="Начало действия"
                                                        format="DD.MM.YYYY"
                                                        slotProps={{
                                                            field: {
                                                                clearable: true
                                                            }
                                                        }}
                                                        name="start"
                                                        onChange={(value) => {
                                                            setFormValue(
                                                                "start",
                                                                value
                                                                    ? dayjs(value).format("YYYY-MM-DD")
                                                                    : ""
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <CustomTooltip tooltip={""} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={2}
                                                justifyContent={"space-between"}
                                            >
                                                <Grid item>
                                                    <StyledDatePicker
                                                        value={dayjs(form.end)}
                                                        label="Окончание действия"
                                                        format="DD.MM.YYYY"
                                                        slotProps={{
                                                            field: {
                                                                clearable: true
                                                            }
                                                        }}
                                                        name="end"
                                                        onChange={(value) =>
                                                            setFormValue(
                                                                "end",
                                                                value
                                                                    ? dayjs(value).format("YYYY-MM-DD")
                                                                    : ""
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <CustomTooltip tooltip={""} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledTextField
                                            placeholder="Описание"
                                            label="Описание"
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            id="description"
                                            value={form.description}
                                            onChange={(value) =>
                                                setFormValue("description", value.target.value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <ButtonSquare
                                disabled={Object.values(errors).some((value) => value !== "")}
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto"
                                }}
                                onClick={handleSubmit}
                            >
                                {form.guid ? "Изменить" : "Создать"}
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default AddContract;
