import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { $contractsDataStore } from "../../store/ContractsStore";
import { useState } from "react";
import { Dialog, DialogTitle, Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { ButtonSquare, ExportButton } from "@synapse/frontend-react";
import "dayjs/locale/ru";
import dayjs, { Dayjs } from "dayjs";
import axios from "../../axios";
import Notification from "../Notification";
import { toast } from "react-toastify";
import { StyledTextField } from "../../Pages/Contracts/ContractDetail";

const StyledDatePicker = styled(DatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: "250px !important",
    background: "white",
    borderRadius: 12,
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px 0 0 12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});
const StyledDatePickerSecond = styled(StyledDatePicker)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderLeftWidth: 0,
            borderRadius: "0 12px 12px 0"
        }
    }
});

const ContractReport = observer(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState({
        start: dayjs(yesterday).startOf("month"),
        end: dayjs(yesterday)
    });
    const [mailDialogOpen, setMailDialogOpen] = useState(false);
    const [mail, setMail] = useState("");
    const [errors, setErrors] = useState({
        start: "",
        end: ""
    });

    const { contractDetail } = $contractsDataStore;

    const handleDates = (name: string, value: Dayjs) => {
        setDates({ ...dates, [name]: value });
    };

    useEffect(() => {
        checkForErrors();
    }, [dates]);

    const checkForErrors = () => {
        // Проверка на ошибки
        let startTextError = "";
        let endTextError = "";

        // Проверка: если даты отсутствуют
        if (!dates.start.isValid()) {
            startTextError = "Некорректно заполненное поле";
        }
        if (!dates.end.isValid()) {
            endTextError = "Некорректно заполненное поле";
        }

        if (dates.start.isValid() && dates.end.isValid()) {
            const startUnix = dates.start.unix();
            const endUnix = dates.end.unix();

            if (startUnix > endUnix) {
                startTextError = "Дата начала не может быть позже даты окончания";
                endTextError = "Дата окончания не может быть раньше даты начала";
            }

            //  Количество миллисекунд в одном дне
            const secondsInDay = 60 * 60 * 24;

            // Проверка, чтобы разница не превышала 120 дней
            const differenceInDays = (endUnix - startUnix) / secondsInDay;

            if (differenceInDays > 120) {
                startTextError = "Разница между датами превышает 120 дней";
                endTextError = "Разница между датами превышает 120 дней";
            }
        }

        if (startTextError || endTextError) {
            const fieldsErrors = { start: startTextError, end: endTextError };
            setErrors(fieldsErrors);
            return true;
        } else {
            setErrors({ start: "", end: "" });
            return false;
        }
    };

    const exportReport = async () => {
        if (checkForErrors()) return;
        setLoading(true);
        const request = {
            contract: contractDetail?.guid,
            start: dates.start.format("YYYY-MM-DD"),
            end: dates.end.format("YYYY-MM-DD")
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_zonesreport",
            data: request
        };

        try {
            const response = await axios.request(config);

            const data = response?.data?.data;

            require("downloadjs")(atob(data.content), data.name, data.content_type);
        } catch (error: any) {
            Notification({
                title: "Не удалось выгрузить отчёт",
                type: toast.TYPE.ERROR
            });
        }
        setLoading(false);
    };

    const mailReport = () => {
        if (checkForErrors()) return;
        setLoading(true);

        // Регулярное выражение для проверки email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Проверка, соответствует ли переданный email регулярному выражению
        if (!emailRegex.test(mail)) {
            Notification({
                title: "Неверный формат email",
                type: toast.TYPE.ERROR
            });
            setLoading(false);
            return;
        }

        const request = {
            contract: contractDetail?.guid,
            start: dates.start.format("YYYY-MM-DD"),
            end: dates.end.format("YYYY-MM-DD"),
            mail
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_mailZonesReport",
            data: request
        };

        Notification({
            title: "Отчет отправляется. После отправки письма вы получите дополнительное уведомление",
            type: toast.TYPE.SUCCESS
        });
        axios
            .request(config)
            .then(() => {
                Notification({
                    title: "Отчет отправлен на почту " + request.mail,
                    type: toast.TYPE.SUCCESS
                });
            })
            .catch((error) => {
                Notification({
                    title: "Не удалось отправить отчет на почту",
                    type: toast.TYPE.ERROR
                });
            });
        setMailDialogOpen(false);
        setLoading(false);
    };

    return (
        <>
            <Grid
                container
                style={{
                    minHeight: 250
                }}
            >
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                        <StyledDatePicker
                            label="Дата с"
                            format="DD.MM.YYYY"
                            minDate={dayjs("2024-07-01")}
                            maxDate={dayjs(yesterday)}
                            defaultValue={dates.start}
                            name="start"
                            slotProps={{
                                field: {
                                    clearable: false
                                },
                                textField: {
                                    error: !!errors.start,
                                    helperText: errors.start,
                                    onFocus: () => setErrors({ start: "", end: errors.end })
                                }
                            }}
                            onChange={(value) => {
                                value && handleDates("start", dayjs(value));
                            }}
                        />
                        <StyledDatePickerSecond
                            label="Дата по"
                            minDate={dayjs("2024-07-01")}
                            maxDate={dayjs(yesterday)}
                            defaultValue={dates.end}
                            format="DD.MM.YYYY"
                            name="end"
                            slotProps={{
                                field: {
                                    clearable: false
                                },
                                textField: {
                                    error: !!errors.end,
                                    helperText: errors.end,
                                    onFocus: () => setErrors({ start: errors.start, end: "" })
                                }
                            }}
                            onChange={(value) => {
                                value && handleDates("end", dayjs(value));
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <ExportButton
                        onClick={exportReport}
                        loading={loading}
                        disabled={loading}
                        style={{
                            height: 56
                        }}
                    />
                </Grid>
                <Grid item>
                    <ExportButton
                        onClick={() => !checkForErrors() && setMailDialogOpen(true)}
                        loading={loading}
                        disabled={loading}
                        style={{
                            height: 56
                        }}
                    >
                        Отправить на почту
                    </ExportButton>
                </Grid>
            </Grid>
            <Dialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)}>
                <Grid container spacing={1} sx={{ padding: "25px" }} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <DialogTitle textAlign={"center"}>Отправка отчета на почту</DialogTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextField
                            label="Электронная почта"
                            InputProps={{
                                type: "email"
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <Grid item>
                                <ButtonSquare variant={"contained"} onClick={mailReport}>
                                    Отправить
                                </ButtonSquare>
                            </Grid>
                            <Grid item>
                                <ButtonSquare onClick={() => setMailDialogOpen(false)}>
                                    Отмена
                                </ButtonSquare>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
});
export default ContractReport;
