import { MockMenu } from "../types/mockMenu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import BorderHorizontalIcon from "@mui/icons-material/BorderHorizontal";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import HailIcon from "@mui/icons-material/Hail";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SettingsIcon from "@mui/icons-material/Settings";
import TableChartIcon from "@mui/icons-material/TableChart";
import { $organisationsStore } from "../store/OrganisationsStore";
import { $ukStore } from "../store/UkStore";
import { generatePath } from "react-router-dom";
import { AppRoute } from "./const";

export const getIcon = (icon: string) => {
    const iconComponents: any = {
        tableChartIcon: TableChartIcon,
        connectedTvIcon: ConnectedTvIcon,
        borderHorizontalIcon: BorderHorizontalIcon,
        historyEduIcon: HistoryEduIcon,
        speakerPhoneIcon: SpeakerPhoneIcon,
        hailIcon: HailIcon,
        notificationsIcon: NotificationsIcon,
        editNoteIcon: EditNoteIcon,
        settingsIcon: SettingsIcon,
    };
    const IconComponent = iconComponents[icon];
    return (
        <IconComponent
            viewBox="0 0 24 24"
            style={{
                width: "1em",
                height: "1em",
            }}
        />
    );
};

export const getUKName = (uk: any) => {
    let ukItem = $ukStore.data?.find((item) => item.guid === uk);

    if (!ukItem) return;

    return ukItem.name;
};

export const getOrganisationName = (organisation: any) => {
    let organisationItem = $organisationsStore.data?.find((item) => item.guid === organisation);

    if (!organisationItem) return;

    return organisationItem.name;
};

export const mockMenu: MockMenu = [
    // { name: "Электронный табель", id: 1, icon: "tableChartIcon" },
    {
        name: "Мониторинг",
        id: 2,
        icon: "connectedTvIcon",
        url: generatePath(AppRoute.Monitoring),
        // collapsed: true,
    },
    {
        name: "Зонирование",
        id: 3,
        icon: "borderHorizontalIcon",
        url: generatePath(AppRoute.ZoneList),
        // collapsed: true,
    },
    {
        name: "Договоры",
        id: 4,
        icon: "historyEduIcon",
        url: generatePath(AppRoute.ContractList),
        // collapsed: true,
    },
    {
        name: "Устройства",
        id: 5,
        icon: "speakerPhoneIcon",
        url: generatePath(AppRoute.Devices),
        // collapsed: true,
    },
    {
        name: "Сотрудники",
        id: 6,
        icon: "hailIcon",
        url: generatePath(AppRoute.Members),
        // collapsed: true,
    },
    // { name: "Уведомления", id: 7, icon: "notificationsIcon" },
    // { name: "Отчеты", id: 8, icon: "editNoteIcon" },
    // { name: "Настройки", id: 9, icon: "settingsIcon" },
];

export const mockTimeSheetTableColumns: any = [
    { name: "ID", key: 0 },
    { name: "Управляющая компания", key: 1 },
    { name: "Организация", key: 2 },
    { name: "Номер договора", key: 3 },
    { name: "Начало действия", key: 4 },
    { name: "Окончание действия", key: 5 },
    { name: "Статус", key: 6 },
    { name: "Действия", key: 7 },
];

export const mockTableTimeSheetData: any = [
    {
        ukName: "Test",
        id: 0,
        company: "TestCompany",
        contract: 1,
        startDate: "12.01.2024",
        endDate: "20.01.2024",
        status: "Черновик",
        action: "test",
    },
    {
        ukName: "Test1",
        id: 1,
        company: "TestCompany1",
        contract: 2,
        startDate: "13.01.2024",
        endDate: "21.01.2024",
        status: "Рабочий",
        action: "test",
    },
];

export const mockTimeSheetTable: any = [
    { name: "ФИО сотрудника", icon: "addPerson", key: 0 },
    { name: "Итого", key: 1 },
    { name: "03.02.24", key: 2 },
    { name: "04.02.24", key: 3 },
    { name: "05.02.24", key: 4 },
    { name: "06.02.24", key: 5 },
    { name: "07.02.24", key: 6 },
    { name: "08.02.24", key: 7 },
    { name: "09.02.24", key: 8 },
    { name: "10.02.24", key: 9 },
    { name: "11.02.24", key: 10 },
    { name: "12.02.24", key: 11 },
    { name: "13.02.24", key: 12 },
];

export let mockTimeSheetData: any = [
    {
        employeeName: " Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 1,
    },
    {
        employeeName: "Test Test Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 2,
    },
    {
        employeeName: "Test Test Test Test Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 3,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 4,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 5,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 6,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 7,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 8,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 9,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 10,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 11,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 12,
    },
    {
        employeeName: "Test Test Test",
        total: "Я**дн.**ч В**дн.**ч",
        date1: "8-17",
        date2: "Вых.",
        date3: "19-4",
        date4: "8-17",
        date5: "19-4",
        date6: "Вых.",
        date7: "8-17",
        date8: "Вых.",
        date9: "19-4",
        date10: "8-17",
        date11: "19-4",
        id: 13,
    },
];

/**
 * Парсинг даты в формате Y-m-d или в другом, если формат задан явно
 *
 * @see formatDateTime
 * @see https://www.php.net/manual/en/datetime.format.php
 * @param {Date|string} date
 * @param {string|boolean} format - формат даты, частично совпадает с описанием даты в php (см @see)
 * @returns {string}
 */
export function formatDate(date: Date | string, format: string | boolean = false) {
    if (date === '') {
        return '';
    }
    let d;
    if (typeof date === "object") {
        d = date;
    } else if (typeof date === 'string') {
        d = new Date(date);
    } else {
        return '';
    }

    if (!(d instanceof Date)) {
        return '';
    }
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    if (Number(year) <= 1970) {
        return "";
    }

    if (typeof format !== "string") {
        return [year, month, day].join('-');
    }

    return format
        .replace('y', year.toString().substr(-2, 2))
        .replace('Y', year)
        .replace('m', month)
        .replace('d', day);
}

/**
 * Парсинг даты в формате Y-m-dTh:i:s или в другом, если формат задан явно
 *
 * @see formatDate
 * @see https://www.php.net/manual/en/datetime.format.php
 * @param {Date} date
 * @param {string} separator - если задан format, то игнорируется
 * @param {string|boolean} format - формат даты, частично совпадает с описанием даты в php (см @see)
 * @returns {string}
 */
export function formatDateTime(date: Date | string, separator: string = 'T', format: string | boolean = false) {
    if (date === '') {
        return '';
    }
    let d;
    if (typeof date === "object") {
        d = date;
    } else if (typeof date === 'string') {
        d = new Date(date);
    } else {
        return '';
    }

    if (!(d instanceof Date)) {
        return '';
    }
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    if (Number(year) <= 1970) {
        return "";
    }

    let h = '' + d.getHours(),
        i = '' + d.getMinutes(),
        s = '' + d.getSeconds();

    if (h.length < 2) {
        h = '0' + h;
    }
    if (i.length < 2) {
        i = '0' + i;
    }

    if (s.length < 2) {
        s = '0' + s;
    }

    if (typeof format !== 'string') {
        return [year, month, day].join('-') + separator + [h, i, s].join(':');
    }

    return format
        .replace('y', year.toString().substr(-2, 2))
        .replace('Y', year)
        .replace('m', month)
        .replace('d', day)
        .replace('H', h)
        .replace('i', i)
        .replace('s', s);
}
