import React, { ReactNode } from "react";
import { IconButton, TableCell, TableRow, TableCellProps } from "@mui/material";
import { observer } from "mobx-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { $deviceTableDataStore } from "../../store/DeviceTableStore";
import { Device, DeviceAttach } from "../../types/device";
import { formatDateTime } from "../../utils/utils";
import { $anchorRefStore } from "../../store/AnchorRef";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Circle } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

require("dayjs/locale/ru");

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ru");

function timeAgo(time: Date | string) {
    const dayjsLocal = dayjs(time);
    // const dayjsMoscow = dayjsLocal.add(addHours, 'hour');
    return dayjsLocal.fromNow();
}

interface CustomTableCellProps extends TableCellProps {
    children: ReactNode;
    keyCell: string;
}

const DeviceTableLine = observer(() => {
    const { tableColumns } = $deviceTableDataStore;

    const CustomTableCell: React.FC<CustomTableCellProps> = ({ children, keyCell, ...props }) => {
        const column = tableColumns.find((el) => el.key === keyCell);
        return column?.show ? <TableCell {...props}>{children}</TableCell> : null;
    };

    if (
        $deviceTableDataStore.deviceData?.items.length === undefined ||
        $deviceTableDataStore.deviceData?.items.length === 0
    ) {
        return (
            <TableRow>
                <TableCell colSpan={9} align="center">
                    Нет устройств
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            {$deviceTableDataStore.deviceData?.items.map((tableLine: Device) => {
                let attaches: string[] = [];
                if (tableLine.attach.items.length !== undefined) {
                    tableLine.attach.items.map((attach: DeviceAttach) => {
                        return attaches.push(
                            attach.name +
                                " (с " +
                                formatDateTime(attach.start, "", "d.m.Y H:i:s") +
                                (attach.end !== ""
                                    ? " по " + formatDateTime(attach.end, "", "d.m.Y H:i:s")
                                    : "") +
                                ")"
                        );
                    });
                }

                return (
                    <TableRow
                        key={tableLine.guid}
                        component={"tr"}
                        sx={{
                            position: "relative",
                            "&:hover": {
                                backgroundColor: "#f8f8f8 !important",
                                boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.06)"
                            }
                        }}
                    >
                        <CustomTableCell
                            keyCell="imei"
                            style={{
                                textWrap: "nowrap"
                            }}
                        >
                            {tableLine.imei}
                        </CustomTableCell>
                        <CustomTableCell keyCell="model" style={{ textWrap: "nowrap" }}>
                            {tableLine.model}
                        </CustomTableCell>
                        <CustomTableCell keyCell="phone" style={{ textWrap: "nowrap" }}>
                            {tableLine.phone}
                        </CustomTableCell>
                        <CustomTableCell keyCell="name">{tableLine.name}</CustomTableCell>
                        <CustomTableCell keyCell="description">
                            {tableLine.description}
                        </CustomTableCell>
                        <CustomTableCell keyCell="status_date" style={{ textWrap: "nowrap" }}>
                            <Tooltip
                                arrow
                                title={
                                    tableLine.status_date
                                        ? formatDateTime(tableLine.status_date, "", "d.m.y H:i:s")
                                        : "Никогда"
                                }
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Circle
                                        htmlColor={
                                            tableLine.status === "online"
                                                ? "green"
                                                : tableLine.status === "offline"
                                                ? "red"
                                                : "black"
                                        }
                                        style={{
                                            fontSize: 14,
                                            marginRight: 5
                                        }}
                                    />
                                    <span>
                                        {tableLine.status_date
                                            ? timeAgo(new Date(tableLine.status_date))
                                            : ""}
                                    </span>
                                </span>
                            </Tooltip>
                        </CustomTableCell>
                        <CustomTableCell keyCell="last_online">
                            <Tooltip
                                arrow
                                title={
                                    tableLine.last_online
                                        ? formatDateTime(tableLine.last_online, "", "d.m.y H:i:s")
                                        : "Никогда"
                                }
                            >
                                <span>
                                    {tableLine.last_online
                                        ? timeAgo(new Date(tableLine.last_online))
                                        : "Никогда"}
                                </span>
                            </Tooltip>
                        </CustomTableCell>
                        <CustomTableCell keyCell="attach">
                            {attaches.map((item) => {
                                return <p style={{ margin: 0 }}>{item}</p>;
                            })}
                        </CustomTableCell>
                        <CustomTableCell keyCell="battery" align={"center"}>
                            {tableLine.battery}
                        </CustomTableCell>
                        <CustomTableCell keyCell="action" style={{ textAlign: "center" }}>
                            <IconButton
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(event.currentTarget);
                                    $deviceTableDataStore.setEditDeviceData(tableLine);
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </CustomTableCell>
                    </TableRow>
                );
            })}
        </>
    );
});

export default DeviceTableLine;
