import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { getUKName } from "../../utils/utils";
import { $zoneTableDataStore } from "../../store/ZoneTableStore";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoute } from "../../utils/const";

const ZoneTableLine = observer(() => {
    if (
        $zoneTableDataStore.tableLinedata?.items.length === undefined ||
        $zoneTableDataStore.tableLinedata?.items.length === 0
    ) {
        return <TableRow>
            <TableCell
                colSpan={7}
                align="center"
            >
                Нет зон
            </TableCell>
        </TableRow>;
    }

    let navigate = useNavigate();
    return (
        <>
            {$zoneTableDataStore.tableLinedata?.items?.map((tableLine: any) => {
                return (
                    <TableRow
                        key={tableLine.guid}
                        component={"tr"}
                        onClick={() => {
                            $zoneTableDataStore.setShowZoneDetailes(true);
                            $zoneTableDataStore.setSelectedZoneData(tableLine);
                            $zoneTableDataStore.setNewCoordinates(undefined);
                        }}
                        onDoubleClick={() => {
                            navigate(
                                generatePath(AppRoute.ZoneDetail, {
                                    guid: tableLine.guid
                                })
                            )
                        }}
                        sx={{
                            position: "relative",
                            "&:hover": {
                                backgroundColor: "#f8f8f8 !important",
                                boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.06)",
                            },
                        }}
                    >
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {/* <Link
                        to={generatePath(AppRoute.Issue, {
                            id: String(timeSheet.id),
                        })}
                    > */}
                            {tableLine.id}
                            {/* </Link> */}
                        </TableCell>
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {tableLine.name}
                        </TableCell>
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {getUKName(tableLine.uk)}
                        </TableCell>
                        <TableCell>{tableLine.description}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
});

export default ZoneTableLine;
