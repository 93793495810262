import axios from "../../axios";
import { toast } from "react-toastify";
import { MonitoringData, MonitoringFilterData, MonitoringFilters } from "../../types/monitoring";
import Notification from "../../components/Notification";

export async function getMonitoringFilterInfo(filters: MonitoringFilters) {
    let config = {
        method: "post",
        url: "/GeoService/Monitoring_filters",
        data: filters
    };
    try {
        const response = await axios.request(config);
        let result: MonitoringFilterData = response.data.data;

        if (!result) {
            Notification({
                title: "Не удалось получить ответ",
                type: toast.TYPE.ERROR
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: "Не удалось получить данные",
            type: toast.TYPE.ERROR
        });
    }
}

export async function getMonitoringData(filters: MonitoringFilters) {
    let config = {
        method: "post",
        url: "/GeoService/Monitoring_data",
        data: filters
    };
    try {
        const response = await axios.request(config);
        let result: MonitoringData = response.data.data;

        if (!result) {
            Notification({
                title: "Не удалось получить ответ",
                type: toast.TYPE.ERROR,
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: "Не удалось получить данные",
            type: toast.TYPE.ERROR,
        });
    }
}
