import { observer } from "mobx-react";
import { MapContainer, TileLayer } from "react-leaflet";
import CustomMarker from "./CustomMarker";
import { GeomanControl } from "./Geoman";
import { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppRoute } from "../../utils/const";

const CustomMap = observer(({ ...props }) => {
    const location = useLocation();
    const { guid } = useParams();

    const isZoneDetail = useCallback(() => {
        return location.pathname.startsWith("/zones/") && !!guid;
    }, [location.pathname, guid]);

    const getGeomanControl = useCallback(() => {
        return (
            <section>
                <GeomanControl
                    position="topleft"
                    oneBlock
                    drawPolyline={false}
                    drawCircle={false}
                    drawCircleMarker={false}
                    drawRectangle={false}
                    drawPolygon={false}
                    drawText={false}
                    dragMode={false}
                    rotateMode={false}
                    drawMarker={false}
                    cutPolygon={false}
                    removalMode={false}
                    editMode={false}
                />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </section>
        );
    }, []);

    return (
        <>
            <MapContainer
                zoom={16}
                scrollWheelZoom={"center"}
                style={{
                    ...props.style,
                    height: props?.style?.height ?? 700,
                    width: "100%"
                }}
            >
                <CustomMarker />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {(location.pathname === AppRoute.ZoneNew || isZoneDetail()) && getGeomanControl()}
            </MapContainer>
        </>
    );
});

export default CustomMap;
